import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import { FormattedMessage } from "react-intl";
import Partners from "../components/Partners";
import { useEffect, useRef, useState } from "react";
import ClientScroller from "ui-component/ClientScroller";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import qarm from "../../../assets/images/new-icons/qarm.svg";
import cep from "../../../assets/images/new-icons/cep.svg";
import { FlagIconCode, FlagIcon } from "react-flag-kit";
import avoidance from "../../../assets/images/icons/avoidance_icon.svg";
import removal from "../../../assets/images/icons/removal_icon.svg";
import biochar from "../../../assets/images/icons/biochar.svg";
import biogas from "../../../assets/images/project_types_new/biogas_household.svg";
import dac from "../../../assets/images/project_types_new/dac.svg";
import clean_water from "../../../assets/images/project_types_new/clean_water.svg";
import erw from "../../../assets/images/project_types_new/erw.svg";

import American_Carbon_Registry from "../../../assets/images/certification/American_Carbon_Registry.jpg";
import CDM_icon from "../../../assets/images/certification/CDM_icon.png";
import climate_action_reserve from "../../../assets/images/certification/climate_action_reserve.png";
import gold_standard from "../../../assets/images/certification/640px-The_Gold_Standard_logo.svg.png";
import oncra from "../../../assets/images/certification/oncra_icon.png";
import plan_vivo from "../../../assets/images/certification/Plan_Vivo_icon.png";
import puro from "../../../assets/images/certification/puro.webp";
import social_carbon from "../../../assets/images/certification/Social_Carbon.png";
import verra_ccbs from "../../../assets/images/certification/Verra_CCBS.jpg";
import verra from "../../../assets/images/certification/verra_verified_carbon_standard_icon.png";
import ebc from "../../../assets/images/certification/ebc.svg";
import dnv from "../../../assets/images/certification/dnv.svg";
import isometric from "../../../assets/images/certification/isometric.png";

import goal1 from "../../../assets/images/social_dev/E_WEB_01.png";
import goal2 from "../../../assets/images/social_dev/E_WEB_02.png";
import goal3 from "../../../assets/images/social_dev/E_WEB_03.png";
import goal4 from "../../../assets/images/social_dev/E_WEB_04.png";
import goal5 from "../../../assets/images/social_dev/E_WEB_05.png";
import goal6 from "../../../assets/images/social_dev/E_WEB_06.png";
import goal7 from "../../../assets/images/social_dev/E_WEB_07.png";
import goal8 from "../../../assets/images/social_dev/E_WEB_08.png";
import goal9 from "../../../assets/images/social_dev/E_WEB_09.png";
import goal10 from "../../../assets/images/social_dev/E_WEB_10.png";
import goal11 from "../../../assets/images/social_dev/E_WEB_11.png";
import goal12 from "../../../assets/images/social_dev/E_WEB_12.png";
import goal13 from "../../../assets/images/social_dev/E_WEB_13.png";
import goal14 from "../../../assets/images/social_dev/E_WEB_14.png";
import goal15 from "../../../assets/images/social_dev/E_WEB_15.png";
import goal16 from "../../../assets/images/social_dev/E_WEB_16.png";
import goal17 from "../../../assets/images/social_dev/E_WEB_17.png";
import Popup from "../components/Popup";
import { Button } from "@mui/material";
import { Link, Link as RouterLink } from "react-router-dom";
import { IconArrowUpCircle } from "@tabler/icons";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, purple } from "@mui/material/colors";
import ThorstenCard from "ui-component/ThorstenCard";

const OurProjects = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2CA4E6",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#E0C2FF",
        light: "#F5EBFF",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#47008F",
      },
    },
  });

  return (
    <div className="testimonial_main">
      <NavBar />
      <div className="testimonial_reviews our_projects">
        <div className="homepage_project_heading_data_content">
          <div className="homepage_project_heading_data_content_value">
            <FormattedMessage
              values={{ break: <br /> }}
              id="OurProjects_title"
              defaultMessage="We only present carbon projects which actually reduce global warming"
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
            style={{ marginBottom: "0.8em", textTransform: "none" }}
          >
            <FormattedMessage
              id="OurProjects_title_sub"
              defaultMessage="Protect your reputation and contribute to global cooling"
            />
          </div>
        </div>

        <div
          className="homepage_project_container"
          style={{
            width: "80%",
            paddingTop: "1em",
            margin: "0 auto",
          }}
        >
          <div className="homepage_project_container_types projects_gap">
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
                <div className="service_banner_image projects_image">
                  <img
                    src="https://storage.googleapis.com/freezecarbon-prd-public/projects/marketplace_single/39.jpg"
                    className="image_width1"
                  />{" "}
                </div>
                <div className="homepage_project_container_types_row_column_heading3">
                  <FormattedMessage
                    values={{ break: <br /> }}
                    id="project_one"
                    defaultMessage="Brothers recycling biowaste"
                  />
                  <FlagIcon
                    code="DE"
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
                <div className="projects_data">
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_class"
                        defaultMessage="Project class"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="carbon_removal"
                        defaultMessage="Carbon removal"
                      />
                      <img
                        src={removal}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_type"
                        defaultMessage="Project type"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="biochar"
                        defaultMessage="Biochar"
                      />
                      <img
                        src={biochar}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="certification_standard"
                        defaultMessage="Certification standard"
                      />
                    </div>
                    <div>
                      <img
                        src={ebc}
                        style={{ width: "150px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="sdgs"
                        defaultMessage="SDGs"
                      />
                    </div>
                    <div className="sdgs_icons">
                      <img
                        src={goal13}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="button_learn_more">
                  {/* <Button variant="contained" className="Button CenterAlign">
                    Learn more
                  </Button> */}
                  <div
                    className="homepage_appbar_nav_get_started_learn_more"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreButton"
                      defaultMessage="Learn more"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* project three  */}

            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
                <div className="service_banner_image projects_image">
                  <img
                    src="https://storage.googleapis.com/freezecarbon-prd-public/projects/marketplace_single/44.jpg"
                    className="image_width1"
                  />{" "}
                </div>
                <div className="homepage_project_container_types_row_column_heading3">
                  <FormattedMessage
                    values={{ break: <br /> }}
                    id="project_three"
                    defaultMessage="Turning animal manure into biogas / Methane avoidance"
                  />
                  <FlagIcon
                    code="KH"
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
                <div className="projects_data">
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_class"
                        defaultMessage="Project class"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="carbon_avoidance"
                        defaultMessage="Carbon avoidance"
                      />
                      <img
                        src={avoidance}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_type"
                        defaultMessage="Project type"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="biogas"
                        defaultMessage="biogas"
                      />
                      <img
                        src={biogas}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="certification_standard"
                        defaultMessage="Certification standard"
                      />
                    </div>
                    <div>
                      <img
                        src={gold_standard}
                        style={{ width: "100px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="sdgs"
                        defaultMessage="SDGs"
                      />
                    </div>
                    <div className="sdgs_icons">
                      <img
                        src={goal2}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                      <img
                        src={goal7}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                      <img
                        src={goal13}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="button_learn_more">
                  {/* <Button variant="contained" className="Button CenterAlign">
                    Learn more
                  </Button> */}
                  <div
                    className="homepage_appbar_nav_get_started_learn_more"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreButton"
                      defaultMessage="Learn more"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* project four  */}

            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
                <div className="service_banner_image projects_image">
                  <img
                    src="https://storage.googleapis.com/freezecarbon-prd-public/projects/marketplace_single/41.jpg"
                    className="image_width1"
                  />{" "}
                </div>
                <div className="homepage_project_container_types_row_column_heading3">
                  <FormattedMessage
                    values={{ break: <br /> }}
                    id="project_four"
                    defaultMessage="Direct Air Capture by leveraging existing infrastructure"
                  />
                  <FlagIcon
                    code="DE"
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
                <div className="projects_data">
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_class"
                        defaultMessage="Project class"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="carbon_removal"
                        defaultMessage="Carbon removal"
                      />
                      <img
                        src={removal}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_type"
                        defaultMessage="Project type"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="dac"
                        defaultMessage="Direct Air Capture"
                      />
                      <img
                        src={dac}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="certification_standard"
                        defaultMessage="Certification standard"
                      />
                    </div>
                    <div>
                      <img
                        src={puro}
                        style={{ width: "100px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="sdgs"
                        defaultMessage="SDGs"
                      />
                    </div>
                    <div className="sdgs_icons">
                      <img
                        src={goal9}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                      <img
                        src={goal13}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="button_learn_more">
                  {/* <Button variant="contained" className="Button CenterAlign">
                    Learn more
                  </Button> */}
                  <div
                    className="homepage_appbar_nav_get_started_learn_more"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreButton"
                      defaultMessage="Learn more"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* project five  */}

            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
                <div className="service_banner_image projects_image">
                  <img
                    src="https://storage.googleapis.com/freezecarbon-prd-public/projects/marketplace_single/23.jpg"
                    className="image_width1"
                  />{" "}
                </div>
                <div className="homepage_project_container_types_row_column_heading3">
                  <FormattedMessage
                    values={{ break: <br /> }}
                    id="project_five"
                    defaultMessage="Direct Air Capture in Industriegebieten"
                  />
                  <FlagIcon
                    code="BR"
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
                <div className="projects_data">
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_class"
                        defaultMessage="Project class"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="carbon_removal"
                        defaultMessage="Carbon removal"
                      />
                      <img
                        src={removal}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_type"
                        defaultMessage="Project type"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="erwShort"
                        defaultMessage="Enhanced Rock Weathering"
                      />
                      <img
                        src={erw}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="certification_standard"
                        defaultMessage="Certification standard"
                      />
                    </div>
                    <div>
                      <img
                        src={isometric}
                        style={{ width: "100px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="sdgs"
                        defaultMessage="SDGs"
                      />
                    </div>
                    <div className="sdgs_icons">
                      {/* Goal 1 */}
                      <img
                        src={goal1}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />

                      {/* Goal 2 */}
                      <img
                        src={goal2}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />

                      <img
                        src={goal3}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />

                      {/* Group for Goals 3 to 6 */}
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {/* Image for Goals 3 to 6 */}
                        <img
                          src={goal4}
                          style={{ width: "40px" }}
                          alt=""
                          className="project__icon"
                        />
                        {/* +4 label */}
                        <div
                          style={{
                            position: "absolute",
                            top: "-10px",
                            left: "25px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px 5px",
                            border: "1px solid black",
                          }}
                        >
                          +13
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button_learn_more">
                  {/* <Button variant="contained" className="Button CenterAlign">
                    Learn more
                  </Button> */}
                  <div
                    className="homepage_appbar_nav_get_started_learn_more"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreButton"
                      defaultMessage="Learn more"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* project six  */}
            {/* <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
                <div className="service_banner_image projects_image">
                  <img
                    src="https://storage.googleapis.com/freezecarbon-prd-public/projects/marketplace_single/20.jpg"
                    className="image_width1"
                  />
                </div>
                <div className="homepage_project_container_types_row_column_heading3">
                  <FormattedMessage
                    values={{ break: <br /> }}
                    id="project_six"
                    defaultMessage="Wells for clean water"
                  />
                  <FlagIcon
                    code="MZ"
                    style={{ width: "30px", height: "20px" }}
                  />
                </div>
                <div className="projects_data">
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_class"
                        defaultMessage="Project class"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="carbon_avoidance"
                        defaultMessage="Carbon avoidance"
                      />
                      <img
                        src={avoidance}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="project_type"
                        defaultMessage="Project type"
                      />
                    </div>
                    <div className="type_description">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="clean_water"
                        defaultMessage="Clean Water"
                      />
                      <img
                        src={clean_water}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="certification_standard"
                        defaultMessage="Certification standard"
                      />
                    </div>
                    <div>
                      <img
                        src={gold_standard}
                        style={{ width: "100px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="type_name">
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="sdgs"
                        defaultMessage="SDGs"
                      />
                    </div>
                    <div className="sdgs_icons">
                     
                      <img
                        src={goal3}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />

                 
                      <img
                        src={goal5}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                      <img
                        src={goal6}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                      <img
                        src={goal13}
                        style={{ width: "40px" }}
                        alt=""
                        className="project__icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="button_learn_more">
               
                  <div
                    className="homepage_appbar_nav_get_started_learn_more"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreButton"
                      defaultMessage="Learn more"
                    />
                  </div>
                </div>
              </div>
            </div> */}

            {/* project seven  */}
            {/* Repeat for each project */}
          </div>

          <div className={`Modal ${displayModal ? "Show" : ""}`}>
            <div className="modal_container">
              <div className="modeal_container_content">
                <div className="modeal_container_content_text">
                  <div className="modal_container_arrow">
                    <IconArrowUpCircle size="30" />{" "}
                    <div>
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreArrow"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </div>
                  </div>

                  <div className="learnMoreHeadingStyle">
                    {" "}
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreHeading"
                      defaultMessage="Safe and efficient carbon neutralization"
                    />{" "}
                  </div>
                </div>
                <div className="modeal_container_content_button">
                  <div className="learn_more_data">
                    {" "}
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreData"
                      defaultMessage="Take action to cool our planet today"
                    />{" "}
                  </div>
                  <ThemeProvider theme={theme}>
                    <Button
                      variant="contained"
                      href="/getting-started#talk-to-expert"
                      style={{
                        minWidth: "320px",
                      }}
                    >
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreButton1"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="/getting-started#talk-to-expert"
                      style={{
                        minWidth: "320px",
                      }}
                    >
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreButton2"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </Button>
                  </ThemeProvider>
                </div>
                <button
                  className="Close"
                  onClick={() => setDisplayModal(!displayModal)}
                >
                  X
                </button>
              </div>
            </div>
          </div>
          <div
            className={`Overlay ${displayModal ? "Show" : ""}`}
            onClick={() => setDisplayModal(!displayModal)}
          />
        </div>
      </div>

      {/* clients  */}

      <div style={{ marginTop: "3em" }}>
        <ClientScroller />
      </div>

      <ThorstenCard />

      {/* partners  */}

      <Partners />

      <Footer />
    </div>
  );
};

export default OurProjects;
