import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import { FormattedMessage } from "react-intl";
import Partners from "../components/Partners";
import { useEffect, useRef, useState } from "react";
import ClientScroller from "ui-component/ClientScroller";
import { Link as RouterLink } from "react-router-dom";
import ThorstenCard from "ui-component/ThorstenCard";
import LinkedIn from "@mui/icons-material/LinkedIn";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import enpulse from "../../../assets/images/clients/enpulse.png";
import vfu from "../../../assets/images/clients/vfu.png";
import robin from "../../../assets/images/clients/robin.jpeg";
import henrik from "../../../assets/images/clients/henrik.webp";
import philip from "../../../assets/images/clients/dkv_phiplip.jpeg";
import dkv from "../../../assets/images/clients/dkv.png";
import exa from "../../../assets/images/clients/exa.jpg";
import race from "../../../assets/images/clients/race.jpg";

const Testimonials = () => {
  return (
    <div className="testimonial_main">
      <NavBar />
      <div className="testimonial_reviews">
        <div className="homepage_project_heading_data_content">
          <div className="homepage_project_heading_data_content_value">
            <FormattedMessage
              id="testimonials"
              defaultMessage="References and inspiration that convince"
            />
          </div>
        </div>
        <div
          className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
          style={{ marginBottom: "0.8em", textTransform: "none" }}
        >
          <FormattedMessage
            id="testimonials_sub"
            defaultMessage="Discover how renowned companies are neutralizing their residual emissions with Freeze Carbon"
          />
        </div>

        <div className="testimonial_reviews_box">
          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={dkv} style={{ height: "80px" }} />
                </div>

                <div>
                  “
                  <FormattedMessage
                    id="customer5_data"
                    defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                  />
                  ”
                </div>
              </div>
              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={philip}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer5_desc"
                      defaultMessage="Robin Knapp"
                    />
                  </div>
                  <FormattedMessage
                    id="customer5_title"
                    defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={enpulse} style={{ height: "80px" }} />
                </div>

                <div>
                  “
                  <FormattedMessage
                    id="customer1_data"
                    defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                  />
                  ”
                </div>
              </div>
              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={robin}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer1_desc"
                      defaultMessage="Robin Knapp"
                    />
                  </div>
                  <FormattedMessage
                    id="customer1_title"
                    defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2 ">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={vfu} style={{ height: "60px" }} />
                </div>

                <div>
                  {" "}
                  <FormattedMessage
                    id="customer2_data"
                    defaultMessage="Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects."
                  />
                </div>
              </div>

              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={henrik}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer2_desc"
                      defaultMessage="Henrik Ohlsen"
                    />
                  </div>
                  <FormattedMessage
                    id="customer2_title"
                    defaultMessage="Managing Director,{break} VfU"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer1_data"
                  defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer1_desc"
                    defaultMessage="Robin Knapp"
                  />
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/robin-christoph-knapp-113979131/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px", opacity: "1" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <FormattedMessage
                  id="customer1_title"
                  defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                />
              </div>
            </div>
          </div> */}
          {/* <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer5_data"
                  defaultMessage='"We like your broad mix of projects, especially that you have many innovative types like biochar and rock weathering."'
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer5_title"
                    defaultMessage="Sustainability Manager"
                  />
                </div>
                <FormattedMessage
                  id="customer5_desc"
                  defaultMessage="Insurance Company"
                />
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer6_data"
                  defaultMessage='"We like that we can pick projects ourselves and actually understand what is behind each. It was very easy to understand each project."'
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer6_title"
                    defaultMessage="ESG Analyst"
                  />
                </div>
                <FormattedMessage
                  id="customer6_desc"
                  defaultMessage="Investment Firm"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Sign up button  */}

      <div className="full_width_getting_started_button">
        <div className="heropage_hero_action">
          <RouterLink
            to="/getting-started"
            className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_carbon_credits"
          >
            <FormattedMessage id="getStartedPrivate" defaultMessage="Sign up" />
          </RouterLink>
          <RouterLink
            to="/getting-started"
            className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_carbon_credits"
          >
            {/* <FormattedMessage id="getStarted" defaultMessage="Sign up" /> */}
            <FormattedMessage id="getStartedCompany" defaultMessage="Sign up" />
          </RouterLink>
          {/* <div className="homepage_appbar_nav_watch_video">
            Watch Video <IconArrowRight color="white" />
          </div> */}
        </div>
      </div>

      {/* clients  */}

      <ClientScroller />

      {/* contact card  */}

      <ThorstenCard />

      <Footer />
    </div>
  );
};

export default Testimonials;
