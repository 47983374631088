import "./App.css";

import React, { useState } from "react";
import { useEffect } from "react";
import { CookieConsent, getCookieConsentValue } from "react-cookie-consent";
import { getCurrencies, getLocales } from "react-native-localize";

import { RouterProvider } from "react-router-dom";

import router from "routes";

// project imports
import Locales from "ui-component/Locales";

import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";

import TagManager from "react-gtm-module";
import { TAG_MGR_ARGS } from "./config";

//preload images
import dashboard from "assets/images/landing/freeze-carbon-landing_hero_v3.svg";
import dashboard_mobile from "assets/images/landing/freeze-carbon-landing-mobile.png";
import Buyer from "assets/images/landing/Buyer.svg";
import Seller from "assets/images/landing/Seller_v03.svg";
import Market from "assets/images/landing/marketplace.png";
import logo from "assets/images/logo.png";
import { IntlProvider } from "react-intl";
import LanguageToggle from "views/new-website/components/LanguageToggle";

import { useDispatch, useSelector } from "react-redux";
import { activeItem } from "store/slices/menu";
import HubSpotCookieBanner from "views/new-website/components/HubspotCookieBanner";
import HubSpotEmbed from "views/new-website/components/HubspotCookieBanner";
import { CSSProperties } from "react";

declare global {
  interface Window {
    _hsp?: any[];
  }
}

function App() {
  const [token, setToken] = React.useState(null as string | null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCookieConsentValue()) {
      TagManager.initialize(TAG_MGR_ARGS);
    }
  });

  console.log(getLocales());
  console.log(getCurrencies());

  function getLocation() {
    if ("geolocation" in navigator) {
      // Check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          // for when getting location is a success
          console.log(
            "latitude",
            position.coords.latitude,
            "longitude",
            position.coords.longitude
          );
        },
        function error(error_message) {
          // for when getting location fails
          console.error(
            "An error has occurred while retrieving location",
            error_message
          );
        }
      );
    } else {
      // geolocation is not supported
      // get your location another way
      console.log("geolocation is not enabled on this browser");
    }
  }

  const messages = {
    "en-US": {
      welcome: "Welcome",
      heroHeading: "Quality & Simplicity in Carbon Credits",
      heroDesc:
        "Freeze Carbon curates carbon projects matching your culture and DNA. Deep quality analysis and transparent assessment to mitigate risks.",
      home: "Home",
      clients: "clients",
      homeHeading:
        "Build a diversified portfolio from a variety of project types",
      certHeading:
        "Strict selection of the best certification standards as a first level of our quality assessment",
      services: "How it works",
      servicesNew: "Our services",
      servicesTitle: "Transparency is at the core of our work",
      servicesSubTitle:
        "Freeze Carbon helps you to improve your carbon footprint efficiently and smoothly",
      knowledge: "Knowledge",
      customers: "References",
      about: "Mission",
      ourProjects: "Our Projects",
      getStartedPrivate: "For individuals",
      getStartedCompany: "For businesses",
      exploreProjects: "Schedule a call",
      signIn: "Log in",
      projects: "Projects",
      biochar: "Biochar",
      erw: "Enhanced Rock Weathering",
      dac: "Direct Air Capture",
      biomass_burial: "Biomass Burial",
      river_hydro: "River Hydro",
      methane_gas: "Methane Gas",
      safe_drinking_water: "Biofuels",
      energy_efficiency: "Energy Efficiency",
      ocean_mineralization: "Ocean Mineralization",
      certifiers_we_partner_with: "Standards we work with closely",
      end_to_end: "End-to-end service",
      end_carbon_credit_service:
        "The most stringent quality assessment and{break} the most conservative approval process in the world",
      personalized_carbon_credit_strategy: "Your personalized strategy",
      portfolio_matching_experts:
        "Our experts tailor a portfolio of projects matching your company's DNA, industry and supply chain, or get inspiration from our pre-built portfolios.",
      quality_analysis_risk_mitigation: "Matching, pre-qualified projects",
      footer_quality_analysis_risk_mitigation:
        "Matching, pre-qualified projects",
      detailed_process_description:
        "Leverage our detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze.",
      cost_effective_procurement: "Transparent cost breakdown",
      footer_cost_effective_procurement: "Transparent cost breakdown",

      cost_description:
        "Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place whilst benefiting from our volume discounts.",
      growth_partners_climate_communities:
        "We are members of these communities",
      learning_academy: "Learning academy",
      learn: "Learn",
      blog: "Blog",
      read: "Read",
      testimonials: "References and inspiration that convince",
      get_started_find_local_and_global_projects:
        "We set up your account and contact you via email within 24 hours.",
      get_started_find_local_and_global_projects_sub:
        "Here we show you CO₂ certificates from all over the world and from a wide variety of project types, from biochar to methane gas avoidance. ",
      book_your_free_consultation_and_demo_to_get_started_right_away:
        "Speak with our carbon experts.",
      book_your_free_consultation_and_demo_to_get_started_right_away_sub:
        "In this session, we will explain how you can use Freeze Carbon to find climate projects, select the right carbon credits and confidently neutralize residual emissions.",
      services_sub: "Protect your reputation and contribute to global cooling",
      service1_desc1:
        "Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company's DNA, industry and supply chain.",
      service1_desc2:
        "Get inspired by our pre-build portfolios or custom design one with our experts.",
      service1_desc3:
        "We support your climate contribution ambition or accelerate your journey towards net-zero emissions.",
      service2_desc1:
        "Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze.",
      service2_desc2: "Ongoing monitoring and tracking of each project.",
      service2_desc3:
        "Comprehensive 5-page report on each project, providing you with all details to make an informed purchase",
      service3_desc1:
        "Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place.",
      service3_desc2:
        "Utilize secure payment options, acting as an escrow service.",
      mission:
        "	 Freeze Carbon is your partner in the battle to find the best way possible to balance unavoidable emissions. Connecting companies with carbon projects whilst creating transparency and trust is our ambition.  Together, we can achieve a net-zero society.",
      principle: "Principles",
      solution: "Why Freeze Carbon",
      solution1: "Existing project",
      solution2: "For your customers",
      solution3: "Custom project",
      solution1_desc: "Instant access to carbon credits for your emissions",
      solution2_desc: "Innovate your webshop offering with carbon credits",
      solution3_desc:
        "A unique project developed for your company and your needs",
      together: "Together",
      together_desc:
        "Only as a collective of market participants, companies and teammates can we make the impossible possible.",
      simple: "Simple",
      simple_desc:
        "We break down the very complex topic of carbon credits into easily digestible pieces.",
      transparent: "Transparent",
      transparent_desc:
        "We take a very close look at carbon projects so that we understand all the details and can communicate them openly to our customers.",
      customer1_data:
        "The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row.",
      customer1_title:
        "Innovation Expert & Project Lead CO₂ Taskforce, EnPulse",
      customer1_desc: "Robin Knapp",
      customer2_data:
        "Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects.",
      customer2_title: "Managing Director, VfU",
      customer2_desc: "Henrik Ohlsen",
      customer3_data:
        "Supported carbon projects in Cambodia and Germany to avoid methane emissions and remove carbon for their 2023 emissions.",
      customer3_title: "Sustainability Manager,",
      customer3_desc: "Mobility Service provider",
      customer4_data:
        "Neutralized all residual emissions they had for one of their consumer-facing companies. ",
      customer4_title: "Sustainability Manger,",
      customer4_desc: "Telecommunication",
      customer5_data:
        "Freeze Carbon offers a very in-depth look at the individual climate projects. This gave us great confidence and we chose exciting and innovative projects that fit perfectly with DKV MOBILITY and our strategy. In the age of decarbonization, we see Freeze Carbon as a strong and professional partner with foresight.",
      customer5_title: "Manager Sustainability, DKV Mobility",
      customer5_desc: "Philipp Anger",
      // customer5_data:
      //   '"We like your broad mix of projects, especially that you have many innovative types like biochar and rock weathering."',
      // customer5_title: "Sustainability Manager,",
      // customer5_desc: "Insurance Company",
      // customer6_data:
      //   '"We like that we can pick projects ourselves and actually understand what is behind each. It was very easy to understand each project."',
      // customer6_title: "ESG Analyst,",
      // customer6_desc: "Investment Firm",
      legalNotice: "Legal notice",
      dataPrivacy: "Data privacy",
      company: "Company",
      header_bullet1: "Cost-efficient procurement",
      header_bullet2: "Complete transparency",
      header_bullet3: "Projects matching your company's DNA",
      gettingStartedHeader: "Sign up to get access to our carbon projects.",
      meeting_division: "OR",
      testimonials_sub:
        "Discover how renowned companies are neutralizing their residual emissions with Freeze Carbon",
      thankYouText: "Thank you!",
      linkedInFollow: "Follow us on LinkedIn",
      our_approach_heading: "Our approach",
      about_desc1:
        "Freeze Carbon was called to live with the mission to create transparency into what a “good” carbon project actually looks like. ",
      about_desc2:
        "We developed the Extra Mile Quality Analysis (“EMQA”) to achieve comparability and transparency in the carbon market. Our multistep process is built like an onion. Every time the assessment of one layer was successful, we move on to the next until we reach the core. ",
      about_desc3:
        "We pre-qualify these projects, so our customers only have to deal with positive projects and can then objectively assess each detail before making a purchase decision. ",
      about_desc4:
        "EMQA was developed by combining conservative measures of the combination of the leading certification institutions and industry experts. ",
      about_desc5:
        "We have curated a list of projects which successfully passed this process. This offering is diverse in project types and regions. ",
      about_desc6:
        "We are not bound to a specific project type, certification institute or country. We are bound by the commitment towards our clients to find great projects for them. ",
      about_desc7:
        "You will therefore find carefully selected projects which you can quickly read up on and get comfortable with before buying carbon credits. ",
      teamHeading: "Our team",
      contactHeading: "Do you have any questions?",
      contactTitle: "Contact",
      OurProjects_title:
        "Examples of our 30 hand picked carbon projects which truely contribute to climate action",
      OurProjects_title_sub:
        "Learn more about our projects and book an introduction call with our experts",
      OurProjects_title_book: "Book an intro call",
      project_one: "Recycling of biowaste in Germany",
      project_two: "Swiss precision biochar",
      project_three: "Turning animal manure into biogas / Methane avoidance",
      project_four: "Direct Air Capture by leveraging existing infrastructure",
      project_five: "Removing carbon and fertilizing tropical soils",
      project_six: "Wells for clean water",
      project_class: "Project class",
      carbon_avoidance: "Carbon avoidance",
      carbon_removal: "Carbon removal",
      project_type: "Project type",
      certification_standard: "Certification standard",
      biogas: "Biogas",
      clean_water: "Safe water",
      erwShort: "Enhanced Rock Weathering",
      learnMoreButton: "Learn more",
      learnMoreArrow: "Take action to cool our planet today",
      learnMoreHeading:
        "Learn more about Freeze Carbon's{break} hand-picked climate projects",
      learnMoreData: "Climate neutrality in just a few steps",
      learnMoreButton1: "Request free account",
      learnMoreButton2: "Talk to a climate expert",
      firstSteps: "First steps",
      step1_one: "Sign up ",
      step1_two: "here",
      step1_three: " to get access to Freeze Carbon",
      step2: "View and compare the best carbon projects on the planet",
      step3: "Build a custom portfolio - with the help of our climate experts",
      step4: "Buy carbon credits from multiple projects in one place",
      step5: "Receive all data points for your CSRD or sustainability report",
      extramiles: "The Extra Mile in Quality",
      extramilesDesc: "The most stringent selection process ",
      extramilesstep1:
        "First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact.",
      extramilesstep2:
        "Secondly, we screen all projects in these categories using external sources. For this, we review the project’s certification document and related climate impact calculations, as well as publicly available information.",
      extramilesstep3:
        "Thirdly, we conduct a detailed quality analysis above and beyond the requirements of the certification standard to ensure only the best of the best projects are listed on Freeze Carbon. This is the most extensive part and most vital to our work as we focus on quality over quantity.",
      extramilesConcl:
        "Review our 10 page due diligence report on all our projects to get a detailed view into each project and make an informed purchase decision.",
      cardTitle1: "Carbon credits",
      cardCaption1: "How carbon credits combat climate change",
      cardTitle2: "Project types",
      cardCaption2: "What are the different sources of credits",
      cardTitle3: "Selection criteria",
      cardCaption3: "What to look for in carbon credits",
      cardTitle4: "Pricing factors",
      cardCaption4: "How pricing works for CO2 credits",
      cardTitle5: "Certification standards",
      cardCaption5: "Who issues carbon credits",
      cardTitle6: "Co-Benefits",
      cardCaption6: "What are additional benefits of credits",

      importanceCarbonOffsettingtitle: "The importance of carbon offsetting",
      importanceCarbonOffsettinglastUpdated: "Last updated on 14th Mar 2023",
      importanceCarbonOffsettingsection1Title:
        "How CO2 offset credits can help combat climate change?",
      importanceCarbonOffsettingsection1TextPart1:
        "A Carbon dioxide (CO2) offset project is an initiative to reduce greenhouse gas (GHG) emissions to mitigate climate change. The most common type of offset project is planting trees, which absorb CO2 as they grow. Other popular offset projects include investing in renewable energy, energy efficiency, and GHG destruction. Carbon credits are issued in the equivalent of one tonne of CO2 avoided or removed.",
      importanceCarbonOffsettingsection1TextPart2:
        "These credits can be used to effectively ‘net out’ emissions. It is important to note that a company should first invest in reducing all GHG factors and only turn to carbon credits to balance out emissions which cannot be avoided. CO2 offset credits can balance emissions from any source, including cars, power plants, factories, and other business activities. Businesses can offset their carbon dioxide emissions by undertaking an equivalent action to reduce carbon dioxide elsewhere (not location dependent). A company might, for example, purchase carbon offsets to account for the greenhouse gases emitted by the air travel of their staff.",
      importanceCarbonOffsettingsection2Title:
        "What are key requirements of carbon credits?",
      importanceCarbonOffsettingsection2Text:
        "There are many types of projects that can create credits, but they all must meet certain criteria to be eligible. The World Bank has put together some guidelines on what makes a good offset project",
      importanceCarbonOffsettingsection2TextPoint1:
        "The project must result in measurable, verified reductions in greenhouse gas emissions",
      importanceCarbonOffsettingsection2TextPoint2:
        "The reductions must be additional, meaning the reduction would not have occurred without the presence of issued credits (e.g., due to economical invalidity without the cash inflow from selling CO2 offset credits).",
      importanceCarbonOffsettingsection2TextPoint3:
        " The impact must be permanent and should last indefinitely or at least for 50-100 years after the project has ended.",
      openProject: "(an in-depth review of more criteria can be found here)",
      importanceCarbonOffsettingsection3Title: "Who issues carbon credits?",
      importanceCarbonOffsettingsection3TextPart1: `A handful of international `,
      importanceCarbonOffsettingsection3TextPart2: "certification standards",
      importanceCarbonOffsettingsection3TextPart3:
        " investigate projects based on transparent calculation of emission avoidance/reduction, additionality, permanence/durability, leakage, no double counting, and that no social harm is caused ",
      importanceCarbonOffsettingsection3TextPart4:
        "(details about credit certification criteria)",
      importanceCarbonOffsettingsection3TextPart5:
        ". Verra (c. 64% market share) and Gold Standard (c. 14% market share) are the most well known, where the latter is the most rigorous certification to obtain.",
      importanceCarbonOffsettingsection4Title:
        "How do carbon credit projects differ?",
      importanceCarbonOffsettingsection4Text: "There are a ",
      importanceCarbonOffsettingsection4TextPart1: "variety of projects",
      importanceCarbonOffsettingsection4TextPart2:
        "that generate CO2 offset credits, including renewable energy projects, reforestation projects, and GHG capture projects. By supporting these projects, businesses can offset their unavoidable carbon emissions and help to mitigate the negative impacts of climate change.",
      importanceCarbonOffsettingsection4TextPart3:
        " The location of the project does not impact local CO2 levels, as emissions travel quickly around the globe. There are, however, other factors why companies choose offset projects in a specific region, e.g., it's their home market, customers and/or suppliers are based there, social impact of the project on the community.",
      importanceCarbonOffsettingfinalText:
        "By supporting projects that reduce or remove greenhouse gases from the atmosphere, we can all play a role in combatting climate change and become a more sustainable society.",
      importanceCarbonOffsettingfinalTextPart1BOLD:
        "Learn how Freeze Carbon can support you",
      importanceCarbonOffsettingfinalTextPart1:
        "to find high-quality carbon credits to balance your unavoidable CO2 emissions and",
      importanceCarbonOffsettingfinalTextPart2: "Sign up today",

      economicsCarbonOffsettingTitle: "The economics of carbon offsetting",
      lastUpdated: "Last updated on 14th Mar 2023",
      understandingPricingCO2Credits:
        "Understanding how pricing works for CO2 credits",
      volatilityTransparencyVCM:
        "Pricing in the voluntary carbon market (VCM) has been rather volatile and lacking transparency in the past. This is mainly due to the historical nature of transacting (buyer had to do a lot of research to contact project developers and negotiate pricing bilaterally). Although price points are more accessible today, it remains a challenge to create full transparency and to determine the specific price for a given project.",
      factorsInfluencingPrice:
        "There are several factors that can influence the price of carbon offset credits on the voluntary market:",
      typeOfProject: "Type of project:",
      typeOfProjectDescription:
        " Different types of carbon offset projects have different costs associated with them. For example, direct air capture & storage projects may be pricier to implement than reforestation projects, and therefore may have higher carbon offset prices.",
      learnMoreAboutProjectTypes: "types of carbon offset projects",
      location: "Location:",
      locationDescription:
        " Carbon offset projects in some locations may be more expensive due to factors such as higher labor or material costs. For example, a renewable energy project in a developing country may have lower costs than a similar project in a developed country. Local supply & demand of credits can also impact the price, i.e., if the demand outweighs the supply in a given country, prices shoot up significantly there. Note that there is no environmental need to link the location where CO2 is emitted to the location of projects where CO2 is avoided/removed.",
      verificationCertification: "Verification and certification:",
      verificationCertificationDescriptionPart1:
        " Carbon offset projects that have been independently verified and certified by organizations such as ",
      goldStandardWebsite: "Gold Standard",
      verificationCertificationDescriptionPart2:
        " may have higher prices due to the additional cost of verification and reputational standing of a given standard.",
      moreOnCertificationStandards: "more on certification standards",
      vintage: "Vintage:",
      vintageDescription:
        "Credits originating from projects further back in the past tend to be less expensive than credits from more recent vintages. This is mainly influenced by market demand focusing on current vintages.",
      coBenefits: "Co-benefits:",
      coBenefitsDescriptionPart1: " There are 17 categories under the  ",
      coBenefitsDescriptionPart2:
        " scheme which are tracked in combination with the CO2 avoidance/removal of a project. The price of projects with meaningful co-benefits can be higher than projects without benefits.",
      unSDGsWebsite: "UN Sustainable Development Goals",
      importantToLookAtAllFactors:
        "It is important to look at all factors in combination before deciding on a specific project and credit.",
      getInTouch: "Get in touch",
      exploreProjectsOnFreezeCarbon:
        " with our team to explore projects on Freeze Carbon and identify which fit best to your company.",
      mainTitle: "Making a difference with quality carbon credits",
      lastUpdated3: "Last updated on 14th Mar 2023",
      criteriaTitle: "Criteria to evaluate carbon credit projects",
      introText:
        "Carbon dioxide (CO2) offset projects are a way for organizations to mitigate their unavoidable greenhouse gas emissions by supporting initiatives that reduce CO2 emissions or remove CO2 from the atmosphere. These projects can include activities such as reforestation, renewable energy generation, and carbon capture & storage.",
      considerationTextPart1:
        "If you're considering supporting a CO2 offset project, there are a few ",
      keyFactorsText: "key factors",
      considerationTextPart2:
        " for consideration to ensure that you're making a meaningful and effective contribution to a sustainable society:",
      measurabilityTransparency: "Measurability and transparency:",
      measurabilityTransparencyDescription:
        " the key is a correct baseline against which impact can be measured and then continuously tracked. Look for projects that are independently verified and transparent about their methods and results. Certified projects must be measurable.",
      additionality: "Additionality:",
      additionalityDescription:
        " choose projects that demonstrate 'additionality,' meaning that they would not have happened without the financial support provided through carbon credits. This ensures that your contribution is making a real difference in reducing emissions.",
      permanentReductions: "Permanent emission reductions:",
      permanentReductionsDescription:
        " seek projects that result in permanent emission reductions, rather than short-term or reversible ones. This could include, for example, carbon capture and storage projects that permanently remove CO2 from the atmosphere. Many certification standards established a buffer (dependent on the project type) to withhold some credits in case CO2 was released accidentally (force of nature). These buffer credits can then be retired. This is especially important for forest projects.",
      noDoubleCounting: "No double-counting:",
      noDoubleCountingDescription:
        " certification standards and project developers must ensure that a given credit is only ‘used’ once, and therefore exclusively associate a credit to one owner (or confirm that only one entity is retiring the credit).",
      leakage: "Leakage:",
      leakageDescription:
        " ensure projects do not result in leakage, where saving one space of forest only shifts the logging activity elsewhere.",
      coBenefits3: "Co-benefits:",
      coBenefitsDescription:
        " study projects that provide additional benefits beyond reducing CO2 emissions, such as protecting biodiversity, or supporting local communities.",
      vintage3: "Vintage:",
      vintageDescription3:
        " one factor to consider is to pick more recent vintages which follow more updated certification protocols. Reversely, CO2 credits from older vintages had a more influential role (i.e., ten years ago, a reduction of one tonne was more impactful based on the amount of air pollution, than a reduction of one tonne today). Alternatively, you could match the year of your emission to the vintage of the credit, which is merely an optical rational (no logical requirement to do so).",
      location3: "Location:",
      locationDescription3:
        " consider supporting projects in areas that are most in need of emission reductions or that will have the greatest impact on global emissions. For example, supporting renewable energy projects in developing countries may have a greater impact on global emissions than in developed countries, where renewable energy is already well established.",
      price: "Price:",
      priceDescription:
        " the cost of one credit is strongly driven by market demand. The quality of a credit is not necessarily linked to the market price as, e.g., a local company in Indonesia seeking to offset their unavoidable emissions could prefer to only buy local credits and therefore drive up prices.",
      certificationOrganizationsPart1:
        "Reputable certification organizations, such as Gold Standard (",
      goldStandardWebsite3: "website",
      certificationOrganizationsPart2:
        "), provide third-party verification of offset projects to ensure that projects are meeting high environmental and social standards across these areas (",
      moreOnCertificationStandards3: "more on certification standards",
      certificationOrganizationsPart3: ").",
      analyzingProjects:
        "By carefully analyzing high-quality projects, you can make an informed decision about which CO2 offset project is right for you and your organization. Carbon offsetting is just one piece of the puzzle in addressing climate change, but it can be a valuable tool for mitigating your emissions and supporting the transition to a low-carbon future.",
      learnHowFreezeCarbonSupports: "Learn how Freeze Carbon can support you",
      balanceEmissions:
        " to find high-quality carbon credits to balance your unavoidable CO2 emissions and ",
      signUpToday: "Sign up today",

      certificationOfCarbonOffsetProjects:
        "Certification of carbon offset projects",
      lastUpdated5: "Last updated on 14th Mar 2023",
      howToFindHighQualityCredits: "How to find high-quality credits",
      carbonOffsetProjectsDescription:
        "Carbon offset projects are initiatives that reduce or remove CO2 from the atmosphere, allowing organizations to offset greenhouse gas emissions they cannot avoid. To ensure that carbon offset projects are held accountable and are truly contributing to climate action, it's important they are verified and certified by independent organizations.",
      largeInstitutesForCertification:
        "There are several large institutes that provide certification for carbon offset projects. The key pillars of their assessment are measurably, permanence, additionality and leakage. The largest two standards account for c. 80% (volume of credits issued 2022) of the voluntary carbon market:",
      goldStandard: "Gold Standard",
      website: "website",
      goldStandardDescriptionPart1:
        "was established in 2003 by a group of environmental NGOs, including WWF and Greenpeace and is based in Geneva, Switzerland. It is a non-profit organization that aims to ensure that carbon offset projects provide high-quality environmental and social benefits. Gold Standard puts the UN Sustainable Development Goals ",
      sdgs: "SDGs",
      goldStandardDescriptionPart2:
        " in the center of their assessment, of which a minimum of three goals must be supported to obtain certification. Projects must meet a set of stringent criteria, including the additionality principle, which ensures that the project would not have happened without the carbon offset funding. This means that the carbon offset funding must be the ‘driving force’ behind the project, and that the project would not have occurred without the funding.",
      verraVcs: "Verra/ Verified Carbon Standard (VCS)",
      verraVcsDescription:
        "was launched in 2005 by a group of businesses, environmental organizations, and offset project developers and is based in Washington DC, USA. Verra is also a non-profit certification standard that verifies the reduction or removal of greenhouse gas emissions from offset projects. Projects must meet a set of principles and criteria, including the additionality principle, and must provide transparent and consistent reporting of emissions.",
      additionalVerificationStandards:
        "In addition to these large verification standards, there are other organizations and programs available, such as Plan Vivo, Climate Action Reserve, American Carbon Registry, and more.",
      pillarsOfCertificationProcess: "Pillars of the certification process",
      certificationProcessDescription:
        "The certification process for carbon offset projects typically involves several steps, including project design and planning, application for certification, independent verification, stakeholder input/consultation and ongoing monitoring and reporting. This process helps to ensure that offset projects are effective in reducing emissions.",
      goldStandardRigorousCriteria:
        "Gold Standard is widely considered to have the most rigorous and demanding certification criteria, making it a highly trusted organization. This is also driven by their strict exclusion of projects types which they consider difficult to certify, e.g., REDD+ (Reducing Emissions from Deforestation and forest Degradation) projects.",
      getInTouch5: "Get in touch",
      getInTouch6: "<b>Get in touch</b>",
      exploreProjects5:
        "with our team to explore projects on Freeze Carbon and identify which fit best to your company.",

      supportingASustainableSociety: "Supporting a sustainable society",
      howCO2OffsetProjectsContributeToSDGs:
        "How CO2 offset projects can contribute to the UN Sustainable Development Goals",
      sdgWebsite: "UN Sustainable Development Goals",
      sdgsDescriptionPart2:
        ", are a set of 17 global goals adopted by the United Nations in 2015 with the aim of ending poverty, protecting the planet, and ensuring peace and prosperity for all. CO2 offset projects can contribute to several of these goals by reducing greenhouse gas emissions and addressing climate change, as well as by providing additional benefits such as protecting biodiversity, and supporting local communities.",
      examplesOfProjectsContributingToSDGs:
        "Examples of projects contributing to SDGs",
      renewableEnergySDG7:
        "Offset projects that support renewable energy generation can contribute to SDG #7 (Affordable and Clean Energy) by providing access to reliable, affordable, and sustainable energy sources. Renewable energy projects can also contribute to SDG #8 (Decent Work and Economic Growth) by creating jobs and supporting economic development in the communities where they are implemented.",
      reforestationSDG15:
        "Reforestation projects can contribute to SDG #15 (Life on Land) by protecting and restoring forests, which provide a range of ecosystem services such as water regulation, soil conservation, and habitat for wildlife. In addition, reforestation projects can provide additional benefits such as improving air quality and supporting local communities through the provision of non-timber forest products and livelihood opportunities.",
      energyEfficiencySDG3And11:
        "Energy efficiency projects can contribute to SDG #3 (Good Health and Well-being) by improving air quality, particularly in urban areas where air pollution from open fires (e.g., from three stone cook stoves) is a significant problem. Energy efficiency projects can also contribute to SDG #11 (Sustainable Cities and Communities) by reducing energy demand in buildings, which can reduce the strain on energy systems and make cities more resilient.",
      roleOfCarbonCertificationStandards:
        "Role of carbon certification standards",
      carbonCertificationStandardsDescription:
        "Gold Standard assesses projects along these 17 goals and reports how each project is contributing to a set of minimum three goals. When companies search for carbon credits, they can include SDGs into their evaluation process to create additional value beyond CO2 reductions.",
      solutions: "Solutions",
      solutions_text:
        "Looking for an existing carbon project, want to offer carbon credits to your customers or want to develop your own project?",
      exploreProjectsDescription:
        "with our team to explore projects on Freeze Carbon and identify which fit best to your company.",
      read_more: "Read more...",

      climateActionStrengthened:
        "Climate action strengthened by different carbon offsetting types",
      home_projects_sub_sub:
        "Freeze Carbon is continuously looking for suitable projects. Only about one in eight projects approved by Gold Standard and others meets our internal standards and is approved for our customers.",
      find_projects: "Find climate projects now",
      understandingProjectTypes:
        "<b>Understanding various project types</b> {break}",
      projectsContributeToNetZero:
        "Projects contribute towards a net-zero environment by either 'avoiding' or 'removing' harmful emissions. Both classes of projects play a vital role in decelerating the increase in emissions, but also to rid the atmosphere of existing CO2 and other greenhouse gas (GHG) emissions.",
      avoidCO2Emission:
        "Projects <b>avoid CO2 emission</b> when they e.g., switch from traditional energy sources like fossil fuels to renewable energy sources (hydro, wind, solar, biomass) or recovery and destruction of fugitive waste gases (e.g., industrial/chemical process optimization).",
      removalOfCO2:
        "<b>Removal of CO2</b> can take place in nature (reforestation) or by technology (direct air capture machines) where CO2 is actively removed (filtered) out from the atmosphere.",
      mostCommonProjects: "<b>Most common projects</b> {break}",
      projectTypesDescription:
        "The list of project types stretches from very prominent ones, including improved forest management and REDD+ (Reducing Emissions from Deforestation and forest Degradation) to newer project types, like nature-based innovations (e.g., enhanced weathering) or technological ones (e.g., direct air capture). Below you find the most common types available:",
      agricultureProjects:
        "<b>Agriculture:</b> Improving and innovating traditional practices can result in the avoidance and reduction of emissions. This can include activities such as improving soil health (e.g. via biochar), reducing fertilizer use, and capturing methane emissions from livestock.",
      chemicalProcesses:
        "<b>Chemical processes:</b> The focus lies on reducing CO2 emissions from the production of chemicals, such as plastics and fertilizers. This can include capturing and reusing CO2 emissions (e.g., ozone depleting substances recovery & destruction).",
      directAirCapture:
        "<b>Direct air capture:</b> Large air vents filter out CO2 from the atmosphere and turn it into a solid state (via a chemical reaction). The solid formation can be stored permanently. These projects can be expensive and technically challenging to implement, but innovation and increasing demand will make this a key pillar in the carbon market.",
      enhancedWeathering:
        "<b>Enhanced weathering:</b> This rather new methodology focuses on accelerating the natural processes of CO2 removal that takes place when rock decays (through rain, wind, heat, and time). Grinding these rocks (basalt) to small powder will result in a faster decomposition due to the higher surface area and exposure of the rock to rain and wind. In this process, CO2 from the atmosphere is bound. This can take place on land (forests, fields, agriculture) or on coastlines. Either way, it will eventually be flushed into the oceans, where it will drop to the bottom as solid rock formation.",
      forestryAndLandUse:
        "<b>Forestry & land use:</b> The goal of most projects is to increase and protect forestry, as trees actively remove CO2 from the atmosphere. This segment was historically the most commonly used, but recently the demand for this type shrunk due to other project types being more reliably certified.",
      householdAndCommunity:
        "<b>Household & community:</b> Typical projects in this category focus on reducing firewood by switching to efficient cook stoves, clean water sources or energy from biodigesters to replace firewood altogether.",
      industrialManufacturing:
        "<b>Industrial manufacturing:</b> These projects focus on avoiding CO2 emissions by detecting leaks & repairing industrial gas systems, capturing waste gases, and catching methane from mines.",
      renewableEnergy:
        "<b>Renewable energy:</b> Projects generate electricity from renewable sources such as solar, wind, or hydropower, reducing the need for fossil fuels and therefore avoiding CO2 emissions. Renewable energy projects can be particularly effective in areas without access to reliable electricity, and can provide additional benefits such as job creation and economic development.",
      wasteManagement:
        "<b>Waste management:</b> Landfill gas capture projects involve capturing and burning methane emissions from landfills, reducing their greenhouse gas impact.",
      evaluateProjectTypes:
        "It's important to carefully evaluate the characteristics and impacts of different types of offset projects to ensure that you are making a meaningful and effective contribution to climate action.",
      renewableEnergySDG1:
        "<b>Biochar projects:<b> These projects can help farmers, especially in the Global South or poor rural regions, to produce natural charcoal that improves water retention in the soil. Furthermore, the admixture (if e.g. combined with manure) can also act as a fertilizer and thus improve the harvest yield SDG #1",
    },
    "de-DE": {
      welcome: "Willkommen",
      heroHeading:
        "Erreichen Sie unkompliziert die Klimaziele Ihres Unternehmens",
      heroDesc:
        "Freeze Carbon stellt CO₂-Projekte zusammen, die zu Ihrer Unternehmenskultur und DNA passen.",
      homeHeading:
        "Erstellen Sie ein diversifiziertes Portfolio, das zur DNA Ihres Unternehmens passt.",
      certHeading:
        "Alle Projekte auf Freeze Carbon erfüllen mindestens die Anforderungen der wichtigsten Zertifizierungsstandards",
      home: "Übersicht",
      clients: "kunden",
      services: "So funktioniert’s",
      servicesNew: "Unsere Leistungen",
      servicesTitle: "Transparenz ist für uns ein zentraler Wert",
      servicesSubTitle:
        "Freeze Carbon hilft Ihnen dabei, Ihre Klimabilanz effizient und reibungslos zu verbessern.",

      knowledge: "Wissen",
      customers: "Referenzen",
      about: "Mission",
      getStartedPrivate: "Für Privatpersonen",
      getStartedCompany: "Für Unternehmen",
      signIn: "Einloggen",
      exploreProjects: "Jetzt Gespräch vereinbaren",
      ourProjects: "Klimaprojekte",
      projects: "Projekte",
      biochar: "Pflanzenkohle (Biochar)",
      erw: "Gesteinsmehl (Enhanced Rock Weathering)",
      dac: "Direct Air Capture",
      biomass_burial: "Biomasse vergraben",
      river_hydro: "Wasserkraft (Flüsse)",
      methane_gas: "Methangas vermeidung",
      safe_drinking_water: "Biofuels",
      energy_efficiency: "Energieeffizienz",
      ocean_mineralization: "Ozean mineralisierung",
      certifiers_we_partner_with:
        "Zertifizierer sind die erste Hürde - Unser eigenes Prüfverfahren lässt hiervon nur die besten Projekte zu",
      end_to_end: "Komplettservice",
      end_carbon_credit_service:
        "Die strengste Qualitätsprüfung und{break} das konservativste Zulassungsverfahren der Welt",
      personalized_carbon_credit_strategy: "Ihre personalisierte Strategie",
      quality_analysis_risk_mitigation: "Passende, vorqualifizierte Projekte",
      footer_quality_analysis_risk_mitigation:
        "Passende, vorqualifizierte Projekte",
      detailed_process_description:
        " Nutzen Sie die Berichte unserer detaillierten dreistufigen Qualitätsanalyse. Die Interviews mit Projektentwicklern gehen tiefer als der Zertifizierungsprozess. ",
      portfolio_matching_experts:
        "Unsere Experten stellen für Sie ein Projektportfolio zusammen, das auf Ihre Unternehmens-DNA, Branche und Lieferkette abgestimmt ist, oder lassen Sie sich von unseren vorgefertigten Portfolios inspirieren.",
      cost_effective_procurement: "Transparente Kostenaufschlüsselung",
      footer_cost_effective_procurement: "Transparente Kostenaufschlüsselung",

      cost_description:
        "Freeze Carbon hat mit allen Projektentwicklern Vereinbarungen getroffen, so dass Sie mit einem einzigen Vertrag mit uns bei mehreren Unternehmen einkaufen und von unseren Mengenrabatten profitieren können.",

      growth_partners_climate_communities:
        "Wir sind Mitglied bei den wichtigsten Netzwerken",
      learn: "Lesen",
      learning_academy: "Lernen",
      blog: "Blog",
      read: "Lesen",
      testimonials: "Referenzen und Inspirationen, die überzeugen",
      get_started_find_local_and_global_projects:
        "Wir richten Ihren Zugang ein und benachrichtigen Sie innerhalb von 24 Stunden per E-Mail.",
      get_started_find_local_and_global_projects_sub:
        "Hier zeigen wir Ihnen CO₂-Zertifikate aus aller Welt und von den verschiedensten Projektarten, von Pflanzenkohle bis Methangas-Vermeidung. ",
      book_your_free_consultation_and_demo_to_get_started_right_away:
        "Sprechen Sie mit{break}  unseren CO₂-Experten.",
      book_your_free_consultation_and_demo_to_get_started_right_away_sub:
        "Wir erklären Ihnen in diesem Termin, wie Sie mit Freeze Carbon Klimaprojekte finden, die passenden Zertifikate auswählen und unkompliziert Ihre Restemissionen neutralisieren.",
      home_projects_sub_sub:
        "Wählen Sie aus einer Vielzahl von nationalen und internationalen Klimaschutzprojekten. Unser Prüfprozess ist umfangreich, nur knapp über 16% aller Projekte schaffen es auf unsere Plattform.",
      services_sub:
        "Schützen Sie Ihre Reputation und tragen Sie zur globalen Abkühlung bei",
      service1_desc1:
        "Unsere Experten stellen Ihnen ein Projektportfolio zusammen, das zu Ihren ESG-Zielen (z.B. SBTi, TÜV), Ihrer Unternehmens-DNA, Branche und  Lieferkette passt. Damit Sie glaubwürdige Belege für Ihre Projektauswahl haben. ",
      service1_desc2:
        "Lassen Sie sich von unseren vorgefertigten Portfolios inspirieren oder entwerfen Sie gemeinsam mit unseren Experten ein individuelles Portfolio.",
      service1_desc3:
        "Wir unterstützen Sie bei der Umsetzung Ihres Contribution Claims oder beschleunigen Ihrer Reise in Richtung Netto-Null-Emissionen.",
      service2_desc1:
        "Detailliertes 3-stufiger Qualitätsprozess mit Interviews und Fragebögen, der über den ursprünglichen Zertifizierungsprozess hinausgeht. Nur 16% der von uns analysierten Projekte werden für Sie zum Kauf freigegeben. Sie müssen Sich nicht durch lange Listen unqualifizierter Projekte arbeiten. ",
      service2_desc2: "Laufende Überwachung jedes Projekts.",
      service2_desc3:
        "Umfassender 5-seitiger Bericht über jedes Projekt, der Ihnen alle Details für eine fundierte Kaufentscheidung liefert.",
      service3_desc1:
        "Freeze Carbon hat Vereinbarungen und Verträge mit allen Projektentwicklern, sodass Sie mit einem einzigen Vertrag Zertifikate von mehreren Unternehmen einkaufen können.",
      service3_desc2: "Nutzen Sie unsere sichere Zahlungsoption. ",
      mission:
        "Freeze Carbon ist Ihr Partner im Kampf gegen unvermeidbare Emissionen. Unser Ziel ist es, Unternehmen und CO₂-Projekte zusammenzubringen und gleichzeitig Transparenz und Vertrauen zu schaffen. Gemeinsam können wir eine Netto-Null-Gesellschaft erreichen.",
      principle: "Werte",
      solution: "Wieso Freeze Carbon",
      solution1: "Klimaprojekte mit Freeze Carbon",
      solution2: "Ihr eigenes Projekt",
      solution3: "Integration von Klimaprojekten in Ihren Sales-Flow",
      solution1_desc:
        "Erstellen Sie ein individuell auf Ihr Unternehmen abgestimmtes Portfolio aus den von uns geprüften Klimaschutzprojekten",
      solution2_desc:
        "Freeze Carbon entwickelt ein perfekt auf Sie abgestimmtes Klimaprojekt - inklusive eigenes Branding",
      solution3_desc:
        "Integrieren Sie den Kauf von CO₂-Zertifikaten in Ihren Webshop und Angebote",
      together: "Zusammen",
      together_desc:
        "Nur als ein Kollektiv von Marktteilnehmern, Unternehmen und Teamkollegen können wir das Unmögliche möglich machen.",
      simple: "Einfach",
      simple_desc:
        "Wir brechen das sehr komplexe Thema von CO₂-Zertifikaten in leicht verdauliche Stücke.",
      transparent: "Transparent",
      transparent_desc:
        "Wir schauen uns Klimaschutzprojekte sehr genau an, damit wir alle Details verstehen und diese offen an unsere Kunden kommunizieren können.",
      customer1_data:
        "Die Klimaprojekte, die wir auf Freeze Carbon gefunden haben, passen ausgezeichnet zu unserer ESG-Strategie und den Social Development Goals, die wir fördern. Daher kaufen wir jetzt auch schon zum zweiten Mal in Folge hier unsere CO₂-Zertifikate ein.",
      customer1_title:
        "Innovation Expert & Project Lead CO₂ Taskforce, EnPulse",
      customer1_desc: "Robin Knapp",
      customer1_company: "EnPulse",
      customer2_data:
        "Im Markt für CO₂-Zertifikate spielen Glaubwürdigkeit und Qualität die entscheidende Rolle. Carbon Freeze ist hier der „Honest Marketplace” und bietet eine Plattform an, auf der Unternehmen hochwertige Projekte finden und in sie investieren können.",
      customer2_title: "Managing Director,{break} VfU",
      customer2_desc: "Henrik Ohlsen",
      customer3_data:
        "Unterstützte CO₂-Projekte in Kambodscha und Deutschland, um Methanemissionen zu vermeiden und CO₂ für ihre Restemissionen im Jahr 2023 zu entfernen.",
      customer3_title: "Nachhaltigkeitsmanager,",
      customer3_desc: "Mobilitätsdienstleister",
      customer4_data:
        "Neutralisierung aller verbleibenden Restemissionen, die bei einem ihrer Tochterunternehmen anfallen.",
      customer4_title: "Nachhaltigkeitsbeauftragte,",
      customer4_desc: "Telekommunikation",
      customer5_data:
        "Freeze Carbon bietet einen sehr tiefen Blick in die einzelnen Klimaschutzprojekte. Das gab uns großes Vertrauen und wir haben uns für spannende und innovative Projekte entschieden, die perfekt zu DKV MOBILITY und unserer Strategie passen. Im Zeitalter der Dekarbonisierung sehen wir in Freeze Carbon einen starken und professionellen Partner mit Weitsicht.",
      customer5_title: "Manager Sustainability, DKV Mobility",
      customer5_desc: "Philipp Anger",
      // customer5_data:
      //   '"Uns gefällt Ihre breite Mischung von Projekten, vor allem, dass Sie viele innovative Arten wie Biokohle und Gesteinsmehl haben."',
      // customer5_title: "Nachhaltigkeitsbeauftragte,",
      // customer5_desc: "Telekommunikation",
      // customer6_data:
      //   '"Uns gefällt, dass wir selbst Projekte auswählen können und tatsächlich verstehen, was dahinter steckt. Es war sehr einfach, jedes Projekt kennenzulernen."',
      // customer6_title: "ESG-Analyst,",
      // customer6_desc: "Investmentfirma",
      legalNotice: "Impressum",
      dataPrivacy: "Datenschutz",
      company: "Firma",
      header_bullet1: "Kosteneffizienter Einkaufen",
      header_bullet2: "Vollständige Transparenz",
      header_bullet3: "Passende Projekte für Ihr Portfolio",
      gettingStartedHeader:
        "Erhalten Sie jetzt Zugang zu{break} einer Vielzahl an Klimaprojekten.",
      meeting_division: "ODER",
      testimonials_sub:
        "Entdecken Sie, wie renommierte Unternehmen über Freeze Carbon Ihren CO₂-Footprint signifikant verbessern",
      thankYouText: "Danke schön!",
      linkedInFollow: "Folgen Sie uns auf LinkedIn",
      our_approach_heading: "Unsere Philosophie",
      about_desc1:
        "Wir haben Freeze Carbon ins Leben gerufen, um Transparenz zu schaffen, wie ein „gutes“ CO₂-Projekt tatsächlich aussieht. ",
      about_desc2:
        "Dazu haben wir die Extra Mile Quality Analysis („EMQA“) entwickelt, um Vergleichbarkeit und Transparenz zwischen CO₂-Zertifikaten zu schaffen. Unser mehrstufiger Prozess ist wie eine Zwiebel aufgebaut. Jedes Mal, wenn die Bewertung einer Schicht erfolgreich war, gehen wir zur nächsten über, bis wir den Kern erreichen. ",
      about_desc3:
        "Wir prüfen diese Projekte vorab, sodass sich unsere Kunden nur mit positiven Projekten befassen müssen und dann jedes Detail objektiv bewerten können, bevor sie eine Kaufentscheidung treffen. ",
      about_desc4:
        "EMQA wurde durch die Kombination konservativer Maßnahmen der führenden Zertifizierungsinstitutionen und Branchenexperten entwickelt. ",
      about_desc5:
        "Wir haben eine Liste von Projekten zusammengestellt, die diesen Prozess erfolgreich durchlaufen haben. Dieses Angebot ist nach Projekttypen und Regionen diversifiziert. ",
      about_desc6:
        "Wir sind nicht an einen bestimmten Projekttyp, ein bestimmtes Zertifizierungsinstitut oder ein bestimmtes Land gebunden. Wir sind unseren Kunden gegenüber verpflichtet, großartige Projekte für sie zu finden. ",
      about_desc7:
        "Sie werden daher sorgfältig ausgewählte Projekte finden, über die Sie sich schnell informieren und mit denen Sie sich vertraut machen können, bevor Sie Emissionsgutschriften kaufen. ",
      teamHeading: "Unser team",
      contactHeading: "Sie haben noch Fragen?",
      contactTitle: "Kontakt",
      OurProjects_title:
        "Beispiele unserer 30 handverlesenen CO₂-Projekte, die wirklich zum Klimaschutz beitragen",
      OurProjects_title_sub:
        "Erfahren Sie mehr über unsere Projekte und buchen Sie ein Kennenlernen mit unseren Experten",
      OurProjects_title_book: "Kennenlernen vereinbaren",
      project_one: "Recycling von Bioabfällen in Deutschland",
      project_two: "Biochar mit Schweizer Präzision",
      project_three: "Tiermist für Biogas / Methan-Vermeidung",
      project_four: "Direct Air Capture in Industriegebieten",
      project_five: "CO₂ Entfernung und Düngung tropischer Böden",
      project_six: "Brunnen für sauberes Wasser",
      project_class: "Projektklasse",
      carbon_avoidance: "CO₂-Vermeidung",
      carbon_removal: "CO₂-Entfernung",
      project_type: "Projekttyp",
      certification_standard: "Zertifizierungsstandard",
      biogas: "Biogas",
      clean_water: "Trinkwasser",
      erwShort: "Gesteinsmehl",
      learnMoreArrow: "Tragen Sie aktiv zum Klimaschutz bei",
      learnMoreHeading:
        "Erfahren Sie mehr über die handverlesenen{break} Klimaprojekte von Freeze Carbon",
      learnMoreData: "In wenigen Schritten zur Klimaneutralität",
      learnMoreButton: "Mehr erfahren",
      learnMoreButton1: "Zugang beantragen",
      learnMoreButton2: "Mit einem Klimaexperten sprechen",
      firstSteps: "Erste Schritte",
      step1: "Melden Sie sich hier an, um Zugang zu Freeze Carbon zu erhalten",
      step1_one: "Melden Sie sich ",
      step1_two: "hier",
      step1_three: " an, um Zugang zu Freeze Carbon zu erhalten",
      step2:
        "Sehen und vergleichen Sie die besten Klimaschutzprojekte der Welt",
      step3:
        "Erstellen Sie ein maßgeschneidertes Portfolio - mit Hilfe unserer Klimaexperten",
      step4:
        "Kaufen Sie CO₂-Zertifikate von mehreren Projekten, gesammelt an einem Ort",
      step5:
        "Erhalten Sie alle Datenpunkte für Ihren CSRD order Nachhaltigkeitsbericht",
      extramiles: "Die Extra Meile in Qualität",
      extramilesDesc: "Der weltweit strengste Auswahlprozess",
      extramilesstep1:
        "Zunächst legen wir fest, welche Projekttypen die höchsten Sicherheitsvorkehrungen und die genaueste Berechnung der Klimaauswirkungen bieten. Außerdem suchen wir nach Projekten, die zu den 17 sozialen Entwicklungszielen der Vereinten Nationen (Social Development Goals) beitragen.",
      extramilesstep2:
        "Zweitens überprüfen wir alle Projekte in diesen Kategorien anhand extern verfügbarer Quellen. Dazu prüfen wir das Zertifizierungsdokument des Projekts und die damit verbundenen Berechnungen der Klimaauswirkungen sowie Pressemitteilungen und öffentlich verfügbare Informationen.",
      extramilesstep3:
        "Drittens führen wir eine detaillierte Qualitätsanalyse durch, die über die Anforderungen des Zertifizierungsstandards deutlich hinausgeht. Somit können wir sicherstellen, dass nur die besten der besten Klimaschutzprodukte bei Freeze Carbon gelistet werden. Dies ist der umfangreichste und wichtigste Teil unserer Arbeit, da wir uns auf Qualität statt Quantität konzentrieren.",
      extramilesConcl:
        "Lesen Sie unseren zehnseitigen Due-Diligence-Bericht zu allen unseren Projekten, um einen detaillierten Einblick in jedes Projekt zu erhalten und eine fundierte Kaufentscheidung zu treffen.",
      cardTitle1: "CO₂-Zertifikate",
      cardCaption1: "Wie CO₂-Zertifikate im Kampf gegen",
      cardTitle2: "Projektarten",
      cardCaption2: "Verständnis der verschiedenen Projektarten",
      cardTitle3: "Qualitätskriterien",
      cardCaption3: "Kriterien zur Bewertung von CO₂-Projekten",
      cardTitle4: "Preisfaktoren",
      cardCaption4: "Was steckt hinter dem Preis eines",
      cardTitle5: "Zertifizierungsstandards",
      cardCaption5: "Wie können Sie hochwertige Zertifikate",
      cardTitle6: "Sozialer und wirtschaftlicher Mehrwert",
      cardCaption6: "Wie Klimaschutzprojekte zu den",

      importanceCarbonOffsettingtitle: "Die Bedeutung des CO₂-Ausgleichs",
      importanceCarbonOffsettinglastUpdated:
        "Zuletzt aktualisiert am 14. März 2023",
      importanceCarbonOffsettingsection1Title:
        "Wie CO₂-Zertifikate im Kampf gegen den Klimawandel helfen können",
      importanceCarbonOffsettingsection1TextPart1:
        "Ein CO₂-Projekt ist eine Initiative zur Reduzierung von Treibhausgasemissionen (THG), um den Klimawandel zu mildern. Die häufigste Art von Projekten ist die Aufforstung, bei der Bäume gepflanzt werden, die CO₂ während ihres Wachstums absorbieren. Weitere beliebte Projekte umfassen Investitionen in erneuerbare Energien, Energieeffizienz und die Zerstörung von schädlichen Treibhausgasen. Zu den modernen Projektarten gehören Direct Air Capture und Pflanzenkohle. CO₂-Zertifikate werden in der Menge von einer Tonne CO₂ ausgestellt, die vermieden oder entfernt wurde.",
      importanceCarbonOffsettingsection1TextPart2:
        "Diese Zertifikate können verwendet werden, um Emissionen effektiv „auszugleichen“. Es ist wichtig zu beachten, dass ein Unternehmen zunächst in die Reduzierung aller THG-Faktoren investieren und erst dann auf CO₂-Zertifikate zurückgreifen sollte, um unvermeidbare Emissionen auszugleichen. CO₂-Zertifikate können Emissionen aus jeder Quelle ausgleichen, einschließlich LKWs/ Autos, Energie, Fabriken und anderen Geschäftstätigkeiten. Unternehmen können ihre CO₂-Emissionen ausgleichen, indem sie eine gleichwertige Maßnahme zur Reduzierung von CO₂ an anderer Stelle ergreifen (standortunabhängig). Ein Unternehmen könnte beispielsweise CO₂-Zertifikate kaufen, um die Treibhausgase auszugleichen, die durch die Flugreisen ihrer Mitarbeiter verursacht werden.",
      importanceCarbonOffsettingsection2Title:
        "Was sind die wichtigsten Anforderungen an CO₂-Zertifikate?",
      importanceCarbonOffsettingsection2Text: `Es gibt viele Arten von Projekten, die Zertifikate erzeugen können, aber sie müssen alle bestimmte Kriterien erfüllen, um berechtigt zu sein. Die Weltbank hat einige Richtlinien zusammengestellt, was ein gutes Projekt ausmacht (eine ausführliche Überprüfung weiterer Kriterien finden Sie hier):`,
      importanceCarbonOffsettingsection2TextPoint1:
        "Das Projekt muss messbare, verifizierte Reduktionen der Treibhausgasemissionen bewirken",
      importanceCarbonOffsettingsection2TextPoint2:
        "Die Reduktionen müssen zusätzlich sein, das heißt, die Reduktion wäre ohne das Projekt nicht zustande gekommen und die Unternehmung ist auf CO₂-Zertifikate angewiesen, damit das Projekte finanziell tragfähig ist.",
      importanceCarbonOffsettingsection2TextPoint3:
        " Die Wirkung muss dauerhaft sein und sollte unbefristet oder zumindest 100 Jahre nach Abschluss des Projekts andauern.",
      openProject:
        "(eine ausführliche Überprüfung weiterer Kriterien finden Sie hier)",
      importanceCarbonOffsettingsection3Title:
        "Wer stellt CO₂-Zertifikate aus?",
      importanceCarbonOffsettingsection3TextPart1: `Eine Handvoll internationaler `,
      importanceCarbonOffsettingsection3TextPart2: "Zertifizierungsstandards",
      importanceCarbonOffsettingsection3TextPart3:
        " untersucht Projekte auf der Grundlage transparenter Berechnung von Emissionsvermeidung/-reduktion, Zusätzlichkeit, Permanenz/Dauerhaftigkeit, Leckage, keine Doppelzählung und dass kein sozialer Schaden verursacht wird ",
      importanceCarbonOffsettingsection3TextPart4:
        " (Einzelheiten zu den Zertifizierungskriterien)",
      importanceCarbonOffsettingsection3TextPart5:
        ". Verra (ca. 64% Marktanteil) und Gold Standard (ca. 14% Marktanteil) sind die bekanntesten, wobei Letzterer die strengste Zertifizierung ist, die es zu erhalten gilt. Weiter gibt es Carbon Standard International, Puro Earth und Isometric, die seit einigen Jahren dazugekommen sind.",

      importanceCarbonOffsettingsection4Title:
        "Spielt der Ort des CO₂-Projekts eine Rolle?",
      importanceCarbonOffsettingsection4Text: "Es gibt eine ",
      importanceCarbonOffsettingsection4TextPart1: "Vielzahl von Projekten,",
      importanceCarbonOffsettingsection4TextPart2:
        " die CO₂-Zertifikate erzeugen. Durch die Unterstützung dieser Projekte können Unternehmen ihre unvermeidbaren CO₂-Emissionen ausgleichen und dazu beitragen, die negativen Auswirkungen des Klimawandels zu mildern.",
      importanceCarbonOffsettingsection4TextPart3:
        " Der Standort des Projekts beeinflusst die lokalen CO₂-Werte nicht, da sich Emissionen schnell um der gesamten Welt bewegen. Es gibt jedoch andere Faktoren, warum Unternehmen Projekte in einer bestimmten Region wählen, z.B. ist es ihr Heimatmarkt, Kunden und/oder Lieferanten sind dort ansässig, sozialer Einfluss des Projekts auf die Gemeinschaft.",
      importanceCarbonOffsettingfinalText:
        "Durch die Unterstützung von Projekten, die Treibhausgase aus der Atmosphäre reduzieren oder entfernen, können wir alle eine Rolle im Kampf gegen den Klimawandel spielen und eine nachhaltigere Gesellschaft werden.",
      importanceCarbonOffsettingfinalTextPart1BOLD:
        "Erfahren Sie, wie Freeze Carbon Sie dabei unterstützen kann,",
      importanceCarbonOffsettingfinalTextPart1:
        "hochwertige CO₂-Zertifikate zu finden, um Ihre unvermeidbaren CO₂-Emissionen auszugleichen, und",
      importanceCarbonOffsettingfinalTextPart2: "beginnen Sie noch heute",

      economicsCarbonOffsettingTitle:
        "Was steckt hinter dem Preis eines CO₂-Zertifikats",
      lastUpdated: "Zuletzt aktualisiert am 14. März 2023",
      understandingPricingCO2Credits:
        "Verständnis der Preisgestaltung für CO₂-Zertifikate",
      volatilityTransparencyVCM:
        "Die Preisgestaltung auf dem freiwilligen Kohlenstoffmarkt (VCM) war in der Vergangenheit recht volatil und intransparent. Dies lag hauptsächlich an der historischen Art der Transaktionen (der Käufer musste viel recherchieren, um Projektentwickler zu kontaktieren und Preise bilateral zu verhandeln). Obwohl Preispunkte heute zugänglicher sind, bleibt es eine Herausforderung, vollständige Transparenz zu schaffen und den spezifischen Preis für ein gegebenes Projekt zu bestimmen.",
      factorsInfluencingPrice:
        "Es gibt mehrere Faktoren, die den Preis von CO₂-Zertifikaten auf dem freiwilligen Markt beeinflussen können:",
      typeOfProject: "Projekttyp:",
      typeOfProjectDescription:
        " Verschiedene Arten von CO₂-Zertifikatsprojekten haben unterschiedliche Kosten. Zum Beispiel können Projekte, die zur Direktluftabscheidung und -speicherung (Direct Air Capture) teurer umzusetzen sein als Aufforstungsprojekte und daher höhere CO₂-Zertifikatspreise haben. ",
      learnMoreAboutProjectTypes: "Arten von CO₂-Zertifikatsprojekten",
      location: "Standort: ",
      locationDescription:
        "CO₂-Zertifikatsprojekte in einigen Standorten können aufgrund höherer Arbeits- oder Materialkosten teurer sein. Beispielsweise kann ein Projekt zur erneuerbaren Energie in einem Entwicklungsland geringere Kosten haben als ein ähnliches Projekt in einem Industrieland. Das lokale Angebot und die Nachfrage nach Zertifikaten können ebenfalls den Preis beeinflussen, d.h., wenn die Nachfrage das Angebot in einem bestimmten Land übersteigt, steigen die Preise dort erheblich. Es gibt jedoch keinen ökologischen Grund, den Standort der CO₂-Emissionen mit dem Standort der Projekte zu verknüpfen, bei denen CO₂ vermieden oder entfernt wird.",
      verificationCertification: "Verifizierung und Zertifizierung: ",

      verificationCertificationDescriptionPart1:
        "CO2-Zertifikatsprojekte, die unabhängig verifiziert und von Organisationen wie Gold Standard zertifiziert wurden, ",
      goldStandardWebsite: "Gold Standard",
      verificationCertificationDescriptionPart2:
        " können höhere Preise haben, da zusätzliche Kosten für die Verifizierung anfallen und der Ruf eines bestimmten Standards berücksichtigt wird.",
      moreOnCertificationStandards: "mehr zu Zertifizierungsstandards",
      vintage: "Jahrgang: ",
      vintageDescription:
        "Zertifikate, die von Projekten aus weiter zurückliegenden Jahren stammen, sind tendenziell günstiger als Zertifikate aus neueren Jahrgängen. Dies wird hauptsächlich durch die Marktnachfrage beeinflusst, die sich auf aktuelle Jahrgänge konzentriert.",
      coBenefits: "Zusatznutzen: ",
      coBenefitsDescriptionPart1: "Es gibt 17 Kategorien im Rahmen der  ",
      coBenefitsDescriptionPart2:
        " die in Kombination mit der CO₂-Vermeidung/-Entfernung eines Projekts verfolgt werden. Der Preis von Projekten mit bedeutenden Zusatznutzen kann höher sein als der von Projekten ohne Zusatznutzen.",
      unSDGsWebsite: "UN-Nachhaltigkeitsziele",
      importantToLookAtAllFactors:
        "Es ist wichtig, alle Faktoren in Kombination zu betrachten, bevor Sie sich für ein bestimmtes Projekt und Zertifikat entscheiden.",
      getInTouch: "Kontaktieren Sie uns",
      exploreProjectsOnFreezeCarbon:
        " um Projekte bei Freeze Carbon zu erkunden und herauszufinden, welche am besten zu Ihrem Unternehmen passen.",

      mainTitle:
        "Der Teufel steckt im Detail: Was sind hochwertige CO₂-Zertifikate?",
      lastUpdated3: "Zuletzt aktualisiert am 14. März 2023",
      criteriaTitle: "Kriterien zur Bewertung von CO₂-Projekten",
      introText:
        "CO₂-Projekte sind eine Möglichkeit für Organisationen, ihre unvermeidbaren Treibhausgasemissionen zu mindern, indem sie Initiativen unterstützen, die CO₂-Emissionen reduzieren oder CO₂ aus der Atmosphäre entfernen. Diese Projekte können Aktivitäten wie Pflanzenkohle, Direct Air Capture, Methangas-Vermeidung und Erzeugung erneuerbarer Energien umfassen.",
      considerationTextPart1:
        "Wenn Sie überlegen, ein CO₂-Zertifikatsprojekt zu unterstützen, gibt es einige ",
      keyFactorsText: "wichtige Faktoren",
      considerationTextPart2:
        " zu berücksichtigen, um sicherzustellen, dass Sie einen sinnvollen und effektiven Beitrag zu einer nachhaltigen Gesellschaft leisten:",
      measurabilityTransparency: "Messbarkeit und Transparenz:",
      measurabilityTransparencyDescription:
        "Der Schlüssel ist eine korrekte Basislinie, gegen die der Einfluss gemessen und dann kontinuierlich verfolgt werden kann. Suchen Sie nach Projekten, die unabhängig verifiziert und transparent über ihre Methoden und Ergebnisse sind. Zertifizierte Projekte müssen messbar sein.",
      additionality: "Zusätzlichkeit:",
      additionalityDescription:
        "Wählen Sie Projekte, die „Zusätzlichkeit“ demonstrieren, was bedeutet, dass sie ohne die finanzielle Unterstützung durch CO₂-Zertifikate nicht stattgefunden hätten. Dies stellt sicher, dass Ihr Beitrag einen echten Unterschied bei der Reduzierung der Emissionen macht.",
      permanentReductions: "Permanente Emissionsreduktionen:",
      permanentReductionsDescription:
        "Suchen Sie nach Projekten, die zu permanenten Emissionsreduktionen führen, anstatt zu kurzfristigen oder rückgängig gemacht werden können. Dies könnte unter anderem Direct Air Capture Projekte umfassen, die CO₂ dauerhaft aus der Atmosphäre entfernen. Viele Zertifizierungsstandards haben einen Puffer eingerichtet (abhängig vom Projekttyp), um einige Zertifikate zurückzuhalten, falls CO₂ versehentlich freigesetzt wird (durch Naturgewalten). Diese Pufferzertifikate können dann zurückgezogen werden. Dies ist besonders wichtig für Waldprojekte.",
      noDoubleCounting: "Keine Doppelzählung:",
      noDoubleCountingDescription:
        "Zertifizierungsstandards und Projektentwickler müssen sicherstellen, dass ein bestimmtes Zertifikat nur einmal „verwendet“ wird und daher ausschließlich einem Eigentümer zugeordnet wird (oder bestätigen, dass nur eine Einheit das Zertifikat zurückzieht).",
      leakage: "Leckage:",
      leakageDescription:
        "Stellen Sie sicher, dass Projekte nicht zu Leckage führen, bei der die Rettung eines Waldstücks lediglich die Abholzungsaktivität an einen anderen Ort verlagert.",
      coBenefits3: "Zusatznutzen:",
      coBenefitsDescription:
        "Untersuchen Sie Projekte, die zusätzliche Vorteile neben der Reduzierung von CO₂-Emissionen bieten, wie den Schutz der Biodiversität oder die Unterstützung lokaler Gemeinschaften.",
      vintage3: "Jahrgang:",
      vintageDescription3:
        "Ein Faktor, den Sie berücksichtigen sollten, ist die Auswahl neuerer Jahrgänge, die aktuelleren Zertifizierungsprotokollen folgen. Umgekehrt hatten CO₂-Zertifikate aus älteren Jahrgängen eine einflussreichere Rolle (d.h., vor zehn Jahren war die Reduzierung einer Tonne aufgrund der Luftverschmutzung wichtiger als heute). Alternativ könnten Sie das Jahr Ihrer Emission mit dem Jahrgang des Zertifikats abgleichen, was lediglich ein optischer Ansatz ist (keine logische Notwendigkeit).",
      location3: "Standort:",
      locationDescription3:
        "Erwägen Sie die Unterstützung von Projekten in Gebieten, die am dringendsten Emissionsreduktionen benötigen oder die den größten Einfluss auf die globalen Emissionen haben. Zum Beispiel könnte die Unterstützung von Projekten zur erneuerbaren Energie in Entwicklungsländern einen größeren Einfluss auf die globalen Emissionen haben als in Industrieländern, in denen erneuerbare Energien bereits gut etabliert sind.",
      price: "Preis:",
      priceDescription:
        "Die Kosten eines Zertifikats werden stark von der Marktnachfrage beeinflusst. Die Qualität eines Zertifikats ist nicht unbedingt mit dem Marktpreis verbunden, da z.B. ein lokales Unternehmen in Indonesien, das seine unvermeidbaren Emissionen ausgleichen möchte, möglicherweise nur lokale Zertifikate kaufen möchte und daher die Preise in die Höhe treibt.",
      certificationOrganizationsPart1:
        "Angesehene Zertifizierungsorganisationen wie Gold Standard (",
      goldStandardWebsite3: "Website",
      certificationOrganizationsPart2:
        ") bieten eine Drittverifizierung von Zertifikatsprojekten an, um sicherzustellen, dass Projekte hohe Umwelt- und Sozialstandards in diesen Bereichen erfüllen (",
      moreOnCertificationStandards3: "mehr zu Zertifizierungsstandards",
      certificationOrganizationsPart3: ").",
      analyzingProjects:
        "Durch die sorgfältige Analyse hochwertiger Projekte können Sie eine fundierte Entscheidung darüber treffen, welches CO₂-Zertifikatsprojekt für Sie und Ihre Organisation am besten geeignet ist. CO₂-Ausgleich ist nur ein Teil des Puzzles zur Bewältigung des Klimawandels, kann jedoch ein wertvolles Werkzeug zur Minderung Ihrer Emissionen und zur Unterstützung des Übergangs zu einer kohlenstoffarmen Zukunft sein.",
      learnHowFreezeCarbonSupports:
        "Erfahren Sie, wie Freeze Carbon Sie dabei unterstützen kann",
      balanceEmissions:
        "hochwertige CO₂-Zertifikate zu finden, um Ihre unvermeidbaren CO₂-Emissionen auszugleichen, und ",
      signUpToday: "beginnen Sie noch heute.",

      certificationOfCarbonOffsetProjects: "Zertifizierung von CO₂-Projekten",
      lastUpdated5: "Zuletzt aktualisiert am 14. März 2023",
      howToFindHighQualityCredits:
        "Wie können Sie hochwertige Zertifikate finden",
      carbonOffsetProjectsDescription:
        "CO₂-Projekte (Klimaschutzprojekte) sind Initiativen, die CO₂ aus der Atmosphäre reduzieren oder entfernen, sodass Organisationen Treibhausgasemissionen ausgleichen können, die sie nicht vermeiden können. Um sicherzustellen, dass CO₂-Projekte verantwortlich sind und tatsächlich zum Klimaschutz beitragen, ist es wichtig, dass sie von unabhängigen Organisationen verifiziert und zertifiziert werden.",
      largeInstitutesForCertification:
        "Es gibt mehrere große Institute, die Klimaschutzprojekte zertifizieren. Die wichtigsten Säulen ihrer Bewertung sind Messbarkeit, Permanenz, Zusätzlichkeit und Leckage. Die beiden größten Standards machen etwa 80 % (Volumen der ausgestellten Zertifikate 2022) des freiwilligen Kohlenstoffmarktes aus:",
      goldStandard: "Gold Standard",
      website: "Website",
      goldStandardDescriptionPart1:
        "wurde 2003 von einer Gruppe von Umwelt-NGOs, darunter WWF und Greenpeace, gegründet und hat seinen Sitz in Genf, Schweiz. Es ist eine gemeinnützige Organisation, die sicherstellen soll, dass Klimaschutzprojekte hochwertige Umwelt- und Sozialvorteile bieten. Gold Standard stellt die UN-Nachhaltigkeitsziele (SDGs) in den Mittelpunkt ihrer Bewertung, von denen mindestens drei Ziele unterstützt werden müssen, um die Zertifizierung zu erhalten. Projekte müssen eine Reihe strenger Kriterien erfüllen, einschließlich des Zusätzlichkeitsprinzips, das sicherstellt, dass das Projekt ohne die Klimaschutzprojekte nicht stattgefunden hätte. Das bedeutet, dass die Klimaschutzprojekte die „treibende Kraft“ hinter dem Projekt sein muss und das Projekt ohne die Finanzierung nicht durchgeführt worden wäre.",
      sdgs: "SDGs",
      goldStandardDescriptionPart2:
        "in den Mittelpunkt ihrer Bewertung, von denen mindestens drei Ziele unterstützt werden müssen, um die Zertifizierung zu erhalten. Projekte müssen eine Reihe strenger Kriterien erfüllen, einschließlich des Zusätzlichkeitsprinzips, das sicherstellt, dass das Projekt ohne die Klimaschutzprojekte nicht stattgefunden hätte. Das bedeutet, dass die Klimaschutzprojekte die „treibende Kraft“ hinter dem Projekt sein muss und das Projekt ohne die Finanzierung nicht durchgeführt worden wäre.",
      verraVcs: "Verra/ Verified Carbon Standard (VCS)",
      verraVcsDescription:
        "wurde 2005 von einer Gruppe von Unternehmen, Umweltorganisationen und Entwicklern von Ausgleichsprojekten gegründet und hat seinen Sitz in Washington DC, USA. Verra ist ebenfalls ein gemeinnütziger Zertifizierungsstandard, der die Reduktion oder Entfernung von Treibhausgasemissionen aus Ausgleichsprojekten verifiziert. Projekte müssen eine Reihe von Prinzipien und Kriterien erfüllen, einschließlich des Zusätzlichkeitsprinzips, und müssen eine transparente und konsistente Berichterstattung über Emissionen bieten.",
      additionalVerificationStandards:
        "Zusätzlich zu diesen großen Verifizierungsstandards gibt es weitere Organisationen und Programme wie Plan Vivo, Climate Action Reserve, American Carbon Registry und mehr.",
      pillarsOfCertificationProcess: "Säulen des Zertifizierungsprozesses",
      certificationProcessDescription:
        "Der Zertifizierungsprozess für Klimaschutzprojekte umfasst typischerweise mehrere Schritte, darunter Projektgestaltung und -planung, Antrag auf Zertifizierung, unabhängige Verifizierung, Einbeziehung/ Beratung von Stakeholdern sowie laufende Überwachung und Berichterstattung. Dieser Prozess trägt dazu bei, sicherzustellen, dass Ausgleichsprojekte effektiv Emissionen reduzieren.",
      goldStandardRigorousCriteria:
        "Gold Standard gilt weithin als der Standard mit den rigorosesten und anspruchsvollsten Zertifizierungskriterien, was ihn zu einer hoch vertrauenswürdigen Organisation macht. Dies wird auch durch ihren strikten Ausschluss von Projekttypen, die sie als schwer zertifizierbar betrachten, z.B. REDD+ (Reducing Emissions from Deforestation and forest Degradation) Projekte, unterstützt.",
      getInTouch5: "Kontaktieren Sie unser Team",
      exploreProjects5:
        "um Projekte bei Freeze Carbon zu erkunden und herauszufinden, welche am besten zu Ihrem Unternehmen passen.",
      solutions: "Was bekommen Sie",
      solutions_text:
        "Erstellen Sie mit wenigen Klicks ein Klimaportfolio, oder setzen Sie mit uns Ihr eigenes{break} Klimaprojekt auf.",
      supportingASustainableSociety:
        "Unterstützung einer nachhaltigen Gesellschaft",
      howCO2OffsetProjectsContributeToSDGs:
        "Wie Klimaschutzprojekte zu den UN-Nachhaltigkeitszielen beitragen können",
      sdgWebsite: "Die Nachhaltigkeitsziele ",
      sdgsDescriptionPart2:
        "der Vereinten Nationen sind eine Reihe von 17 globalen Zielen, die 2015 von den Vereinten Nationen verabschiedet wurden, um Armut zu beenden, den Planeten zu schützen und Frieden und Wohlstand für alle zu gewährleisten. Klimaschutzprojekte können zu mehreren dieser Ziele beitragen, indem sie Treibhausgasemissionen reduzieren und den Klimawandel bekämpfen sowie zusätzliche Vorteile wie den Schutz der Biodiversität und die Unterstützung lokaler Gemeinschaften bieten.",
      renewableEnergySDG7:
        "<b>Erneuerbare Energieprojekte</b>: Projekte zur Unterstützung der Erzeugung erneuerbarer Energien können zu SDG #7 (Bezahlbare und saubere Energie) beitragen, indem sie den Zugang zu zuverlässigen, erschwinglichen und nachhaltigen Energiequellen ermöglichen. Projekte zur erneuerbaren Energie können auch zu SDG #8 (Menschenwürdige Arbeit und Wirtschaftswachstum) beitragen, indem sie Arbeitsplätze schaffen und die wirtschaftliche Entwicklung in den Gemeinden unterstützen, in denen sie umgesetzt werden.",
      reforestationSDG15:
        "<b>Aufforstungsprojekte</b>: können zu SDG #15 (Leben an Land) beitragen, indem sie Wälder schützen und wiederherstellen, die eine Reihe von Ökosystemdienstleistungen wie Wasserregulierung, Bodenerhaltung und Lebensraum für Wildtiere bieten. Ebenso können Aufforstungsprojekte zusätzliche Vorteile wie die Verbesserung der Luftqualität und die Unterstützung lokaler Gemeinschaften durch die Bereitstellung von Nicht-Holz-Waldprodukten und Lebensgrundlagen bieten.",
      energyEfficiencySDG3And11:
        "<b>Energieeffizienzprojekte</b>: können zu SDG #3 (Gesundheit und Wohlergehen) beitragen, indem sie die Luftqualität verbessern, insbesondere in städtischen Gebieten, in denen Luftverschmutzung durch offene Feuer (z.B. durch Dreistein-Kochherde) ein erhebliches Problem darstellt. Energieeffizienzprojekte können auch zu SDG #11 (Nachhaltige Städte und Gemeinden) beitragen, indem sie die Energienachfrage in Gebäuden reduzieren, was die Belastung der Energiesysteme verringern und Städte widerstandsfähiger machen kann.",
      roleOfCarbonCertificationStandards: "Rolle der Zertifizierungsstandards",
      carbonCertificationStandardsDescription:
        "Gold Standard bewertet Projekte anhand dieser 17 Ziele und berichtet, wie jedes Projekt zu mindestens drei Zielen beiträgt. Wenn Unternehmen nach CO₂-Zertifikaten suchen, können sie die SDGs in ihren Bewertungsprozess einbeziehen, um zusätzlichen Wert über die CO₂-Reduktionen hinaus zu schaffen.",
      exploreProjectsDescription:
        "um Projekte bei Freeze Carbon zu erkunden und herauszufinden, welche am besten zu Ihrem Unternehmen passen.",
      read_more: "Mehr lesen...",

      climateActionStrengthened:
        "Klimaschutz durch verschiedene Arten von CO₂-Zertifikaten stärken",

      understandingProjectTypes:
        "<b>Verständnis der verschiedenen Projektarten</b> {break}",
      projectsContributeToNetZero:
        "Projekte tragen zu einer netto-null Umwelt bei, indem sie schädliche Emissionen entweder <b>„vermeiden“</b> oder <b>„entfernen“</b>. Beide Klassen von Projekten spielen eine wesentliche Rolle dabei, den Anstieg der Emissionen zu verlangsamen und die Atmosphäre von bestehendem CO₂ und anderen Treibhausgasen (THG) zu befreien.",
      avoidCO2Emission:
        "Projekte <b>vermeiden CO₂-Emissionen</b>, wenn sie beispielsweise von traditionellen Energiequellen wie fossilen Brennstoffen auf erneuerbare Energiequellen (Wasser-, Wind-, Solar-, Biomassenenergie) umsteigen oder flüchtige Abgase / Methangas zurückgewinnen und zerstören (z.B. Optimierung von industriellen/chemischen Prozessen).",
      removalOfCO2:
        "Die <b>Entfernung von CO₂</b> kann in der Natur (Aufforstung) oder durch Technologie (Direct Air Capture) erfolgen, bei der CO₂ aktiv aus der Atmosphäre entfernt (gefiltert) wird.",
      mostCommonProjects: "<b>Häufigste Projekte</b> {break}",
      agricultureProjects:
        "<b>Landwirtschaft:</b> Die Verbesserung und Innovation traditioneller Praktiken kann zur Vermeidung und Reduzierung von Emissionen führen. Dies kann Aktivitäten umfassen, wie die Verbesserung der Bodenqualität (z.B. durch Biokohle), die Reduzierung des Düngemittelverbrauchs und das Einfangen von Methanemissionen aus der Viehzucht.",
      chemicalProcesses:
        "<b>Chemische Prozesse:</b> Der Fokus liegt auf der Reduzierung von CO₂-Emissionen bei der Produktion von Chemikalien, wie Kunststoffen und Düngemitteln. Dies kann das Einfangen und Wiederverwenden von CO₂-Emissionen umfassen (z.B. Rückgewinnung und Zerstörung ozonabbauender Substanzen).",
      directAirCapture:
        "<b>Direct Air Capture:</b> Große Luftfilteranlagen entfernen CO₂ aus der Atmosphäre und wandeln es in einen festen Zustand um (durch eine chemische Reaktion). Die feste Formation kann dauerhaft gespeichert werden. Diese Projekte können teuer und technisch herausfordernd sein, aber Innovationen und steigende Nachfrage werden dies zu einer zentralen Säule im Kohlenstoffmarkt machen.",
      enhancedWeathering:
        "<b>Gesteinsmehl:</b> Diese relativ neue Methode konzentriert sich darauf, natürliche Prozesse der CO₂-Entfernung zu beschleunigen, die auftreten, wenn Gestein durch Regen, Wind, Hitze und Zeit verwittert. Das Zerkleinern dieser Gesteine (Basalt) zu feinem Pulver führt zu einer schnelleren Zersetzung aufgrund der größeren Oberfläche und der stärkeren Exposition des Gesteins gegenüber Regen und Wind. Dabei wird CO₂ aus der Atmosphäre gebunden. Dies kann an Land (Wälder, Felder, Landwirtschaft) oder an Küsten stattfinden. In beiden Fällen wird es schließlich in die Ozeane gespült, wo es als feste Gesteinsformation auf den Boden sinkt.",
      forestryAndLandUse:
        "<b>Forstwirtschaft und Landnutzung:</b> Das Ziel der meisten Projekte ist es, die Forstwirtschaft zu erhöhen und zu schützen, da Bäume aktiv CO₂ aus der Atmosphäre entfernen. Dieses Segment war historisch gesehen das am häufigsten verwendete, aber in letzter Zeit ist die Nachfrage nach diesem Typ zurückgegangen, da andere Projekttypen zuverlässiger zertifiziert sind.",
      householdAndCommunity:
        "<b>Haushalte und Gemeinschaften:</b> Typische Projekte in dieser Kategorie konzentrieren sich darauf, den Verbrauch von Brennholz zu reduzieren, indem auf effiziente Kochherde, saubere Wasserquellen oder Energie aus Biogasreaktoren umgestiegen wird, um Brennholz vollständig zu ersetzen.",
      biogasPlants:
        "<b>Biogasanlagen für Bauern:</b> Methangas, das von Tierdung aufsteigt, wird vermieden. Dazu baut der Projektbetreiber tausende Biogasanlagen auf abgelegenen Bauernhöfen. Methangase wird in diesen Anlagen dann aufgefangen und kann zum Heizen und Kochen genutzt werden. Das spart gleichzeitig Geld, da der Bauernhof weniger Öl und Holz als Energiequelle benötigt.",
      industrialManufacturing:
        "<b>Industrielle Fertigung:</b> Diese Projekte konzentrieren sich darauf, CO₂-Emissionen zu vermeiden, indem sie Lecks in industriellen Gassystemen erkennen und reparieren, Abgase einfangen und Methan aus Bergwerken auffangen.",
      renewableEnergy:
        "<b>Erneuerbare Energien:</b> Projekte erzeugen Strom aus erneuerbaren Quellen wie Solar-, Wind- oder Wasserkraft, reduzieren den Bedarf an fossilen Brennstoffen und vermeiden dadurch CO₂-Emissionen. Projekte zur erneuerbaren Energie können besonders effektiv in Gebieten sein, die keinen Zugang zu zuverlässiger Elektrizität haben, und zusätzliche Vorteile wie die Schaffung von Arbeitsplätzen und wirtschaftliche Entwicklung bieten.",
      wasteManagement:
        "<b>Abfallwirtschaft:</b> Projekte zur Erfassung von Deponiegasen umfassen das Auffangen und Verbrennen von Methanemissionen aus Deponien, wodurch deren Treibhausgaswirkung reduziert wird.",
      importanceOfEvaluation:
        "Es ist wichtig, die Merkmale und Auswirkungen verschiedener Arten von Projekten sorgfältig zu bewerten, um sicherzustellen, dass Sie einen sinnvollen und effektiven Beitrag zum Klimaschutz leisten.",
      getInTouch6: "<b>Kontaktieren Sie unser Team</b> ",
      projectTypesDescription:
        "Die Liste der Projekttypen reicht von sehr prominenten, wie verbessertem Forstmanagement und REDD+ (Reducing Emissions from Deforestation and forest Degradation) bis hin zu neueren Projekttypen, wie naturbasierten Innovationen (z.B. Gesteinsmehl) oder technologischen Projekten (z.B. Direct Air Capture). Im Folgenden finden Sie die häufigsten verfügbaren Typen:",
      renewableEnergySDG1:
        "<b>Pflanzenkohle Projekte<b>: Diese Projekte können, insbesondere im Globalen Süden oder armen ländlichen Regionen, Bauern helfen natürliche Kohle herzustellen, die im Ackerboden für verbesserte Wasserspeicherung sorgt. Weiter kann die Beimischung auch als Dünger wirken und somit die Ernte verbessert SDG #1 (Armutsbekämpfung). ",
      find_projects: "Jetzt Klimaprojekte finden",
    },
  };

  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  // ABCD testing landing pages
  const routesToGerman = [
    "/kompensation",
    "/carbon-credits",
    "/CO₂-zertifikate",
    "/klimaschutzprojekt",
  ];

  const shouldSetToGerman = routesToGerman.some((route) =>
    window.location.pathname.includes(route)
  );

  React.useEffect(() => {
    if (shouldSetToGerman) {
      setLanguage("de-DE");
    }
  }, [shouldSetToGerman]);

  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;
  const [language, setLanguage] = React.useState(selectedItem);

  // const languageToUse = "en-US";

  // Select the messages based on the determined language
  const selectedMessages = messages[language];

  return (
    <ThemeCustomization>
      {/* RTL layout */}
      <RTLLayout>
        <Locales>
          {/* <div className="language-toggle">
            <input
              type="radio"
              id="english"
              name="language"
              value="en-US"
              checked={language === "en-US"}
              onChange={handleLanguageChange}
            />
            <label htmlFor="english">English</label>

            <input
              type="radio"
              id="german"
              name="language"
              value="de-DE"
              checked={language === "de-DE"}
              onChange={handleLanguageChange}
            />
            <label htmlFor="german">Deutsch</label>
          </div> */}
          <CookieConsent
            containerClasses="cookie-banner"
            buttonClasses="cookie-button"
            declineButtonClasses="cookie-button"
            style={{
              backgroundColor: "white",
              boxShadow: "0 -1px 8px 0 rgba(0,0,0,0.5)",
              flexDirection: "column",
              color: "black",
              padding: "1em 5em",
            }}
            buttonStyle={{
              padding: "1em 5em",
              backgroundColor: "#2CA4E6",
              color: "white",
              fontSize: "16px",
              margin: "15px 10px 15px 0",
              width: "200px",
            }}
            declineButtonStyle={{
              padding: "1em 5em",
              backgroundColor: "#2CA4E6",
              color: "white",
              fontSize: "16px",
              margin: "15px 10px 15px 0",
              width: "200px",
            }}
            contentStyle={{
              flex: "none",
              lineHeight: "2em",
            }}
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
              } else {
                TagManager.initialize(TAG_MGR_ARGS);
              }
            }}
            enableDeclineButton
            buttonText="Accept"
            declineButtonText="Decline"
          >
            <h1>Usage of Cookies </h1>
            We would like to use marketing cookies to better understand your use
            of this website, to improve our website and to share information
            with our advertising partners. Your consent also covers the transfer
            of your Personal Data in unsafe third countries, involving the risk
            that public authorities may access your Personal Data for
            surveillance and other purposes w/o effective remedies. Detailed
            information about can be found in our Privacy Statement.
          </CookieConsent>
          <>
            {/* HubSpot Cookie Banner */}
            {/* <button
              type="button"
              id="hs_show_banner_button"
              style={{
                backgroundColor: "#0B496B",
                border: "1px solid #0B496B",
                borderRadius: "3px",
                padding: "10px 16px",
                textDecoration: "none",
                color: "#fff",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "normal",
                lineHeight: "inherit",
                textAlign: "left",
                textShadow: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                var _hsp = (window._hsp = window._hsp || []);
                _hsp.push(["showBanner"]);
              }}
            >
              Cookie Settings
            </button> */}

            <IntlProvider locale={language} messages={selectedMessages}>
              <main className="py-1">
                <RouterProvider router={router} />
              </main>
            </IntlProvider>

            <Snackbar />
          </>

          {/* Preload images */}
          <div id="preload">
            <img src={dashboard} />
            <img src={dashboard_mobile} />
            <img src={Buyer} />
            <img src={Seller} />
            <img src={Market} />
            <img src={logo} />
          </div>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
}
export default App;
